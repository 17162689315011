import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { blockToken, unblockToken, getTokenEnquire, getTokenList, importCertificate, generateKeys, certificateIssue, generateKeys2f, certificateIssue2f,
		 blockToken2f, unblockToken2f, removeToken, removeToken2f, changePinByPin, changePinByPin2f, changePinByPuk, changePinByPuk2f,
		 resetToken, resetToken2f, getPublicKeyCurrentUtcDateTime } from '../actions/api'

import { formatDate, cipher } from '../actions/helpers'

import { setTokenEnquire, setSelectedToken, clearSelectedToken, setTokenList, clearTokenEnquire, setShowTokenPopup, setTempId, setUsersPins,
		setShowImportCertificatePopup, setShowActionResult, setActionResultData, setErrorResponse, clearCertificateData, clearCertificateFileName,
		setTokenEnquiresList, setShowGenerateKeyPopup, setGlobalProcessing, setKeyGenerationProcess, setCertificateIssuingProcess, 
		setCertificateImportProcess, setNeedRefresh, setShowTwoFactorSettings, setNeed2fConfirm, setIsElectronicStamp, setCertificateAutoIssuing,
		setActiveView, setShowError, setShowChangePinPopup, setOldPin, setNewPin, setShowSessionEndHandler, clearTokenList, setShowTrusteesPopup,
		setPuk, setShowChangePinByPukPopup, setGeneratedKeysType, setShowTokenPukPopup, setUserPukCode, setShowResetPopup } from '../actions/localStates'

import TokenPinPopup from './TokenPinPopup'
import UserInfoPopup from './UserInfoPopup'
import ActiveKeysView from './ActiveKeysView'
import WaitingKeysView from './WaitingKeysView'
import OtherKeysView from './OtherKeysView'
import ImportCertificatePopup from './ImportCertificatePopup'
import ActionResultInfoPopup from './ActionResultInfoPopup'
import OtherCertificatesView from './OtherCertificatesView'
import OtherCertificatesRequestsView from './OtherCertificatesRequestsView'
import ErrorMessagePopup from './ErrorMessagePopup'
import GenerateKeyPopup from './GenerateKeyPopup'
import TwoFactorContainerPopup from '../containers/TwoFactorContainerPopup'
import TwoFactorConfirmPopup from './TwoFactorConfirmPopup'
import ChangePinPopup from './ChangePinPopup'
import ChangePinByPukPopup from './ChangePinByPukPopup'
import SessionEndPopup from './SessionEndPopup'
import TokenPukPopup from './TokenPukPopup'
import ResetPopup from './ResetPopup'
import TrusteesPopup from './TrusteesPopup'

class Body extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	showTokenActions: false,
	    	showPopup: false,
	    	activeHandler: this.handleRefreshToken.bind(this),
	    	active2fBodyHandler: this.generateKey2f.bind(this),
	    	keyIdentifier: "",
	    	certificateBase64Data: "",
	    	confirm2fTitle: "",
	    	numberOfPinInput: 0,
	    	needToReEnterPin: false,
	    	isActiveView: false,
	    	id: "",
	    	needToGenerateSecondKey: false
	    }

	    this.handleShowTokenActions = this.handleShowTokenActions.bind(this);
	    this.handleCloseTokenActions = this.handleCloseTokenActions.bind(this);

	    this.showImportCertificatePopup = this.showImportCertificatePopup.bind(this);
	    this.showGenerateKeyPopup = this.showGenerateKeyPopup.bind(this);
	    this.showChangePinPopup = this.showChangePinPopup.bind(this);
	    this.showChangePinByPukPopup = this.showChangePinByPukPopup.bind(this);

	    this.showResetTokenPopup = this.showResetTokenPopup.bind(this);
	    this.showTrusteesPopup = this.showTrusteesPopup.bind(this);

	    this.checkTokenOwner = this.checkTokenOwner.bind(this);
	}

	handleShowTokenActions (event) {
		event.preventDefault();
		this.setState({
				showTokenActions: !this.state.showTokenActions
			}, () => {
			document.addEventListener('click', this.handleCloseTokenActions);
		});
	}

	handleCloseTokenActions (event) { 
		this.setState({ showTokenActions: false }, () => {
			document.removeEventListener('click', this.handleCloseTokenActions);
		});  
	}

	handleBlockToken (id) {
		this.props.actions.setTempId(id);
		var _this = this, currentTime = new Date().getTime() / 1000;
		
		if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		};

		if (Object.keys(this.props.userReducer.usersPins).length === 0 && this.props.userReducer.usersPins.constructor === Object) {
			this.setState({activeHandler: this.handleBlockToken.bind(this)})
			this.props.actions.setShowTokenPopup(true);
		} else {
			if (this.props.userReducer.usersPins[id] !== undefined && this.props.userReducer.usersPins[id].length > 0) {
				this.props.actions.setShowTokenPopup(false);
				this.setState({isActiveView: true})

				var jsonObject = {
					pin: this.props.userReducer.usersPins[id]
				};

				var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

				var data = {
					"ciHsmTokenUri": id,
					"encryptedData": cipherResult.encryptedData,
					"secretKey": cipherResult.secretKey
				}

				if (this.props.login.is2fEnabled) {
					this.props.actions.blockToken2f(this.props.base.serviceUrl, this.props.login.accessToken, data)
					.then((response) => {
						if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
							this.setState({confirm2fTitle: "blockToken2fConfirm"})
							this.setState({active2fBodyHandler: this.handleBlockToken2f.bind(this)})
							this.setState({id: id});
							this.props.actions.setNeed2fConfirm(true);
						} else {
							this.props.actions.setErrorResponse(response.json)
							this.setState({showPopup: true})
							this.setState({isActiveView: false})
						}

						return;
					})
					
					return;
				}

				this.props.actions.blockToken(this.props.base.serviceUrl, this.props.login.accessToken, data)
				.then((response) => {
					if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
						this.props.actions.getTokenList(this.props.base.serviceUrl, this.props.login.accessToken)
						.then((response) => {
							this.props.actions.setTokenList(response)

							response.forEach(function (token, index) {
								if (token.uri === id) {
									_this.props.actions.setSelectedToken(index);
								}
							})

							this.props.actions.setShowActionResult(false)
							this.props.actions.setActionResultData({status: "success", action: "blockToken", message: i18next.t("success")})
	                    	this.props.actions.setShowActionResult(true)

						});
					} else {
						var tempData = this.props.userReducer.usersPins;

						delete tempData[id]; 
						this.props.actions.setUsersPins(tempData);

						this.props.actions.setErrorResponse(response.json)
						this.setState({showPopup: true})
					}

					console.log(response);
				});
			} else {
				this.props.actions.setShowTokenPopup(true);
				this.setState({activeHandler: this.handleBlockToken.bind(this)})
			}
		}
	}

	handleBlockToken2f (id) {
		this.props.actions.setTempId(id);
		var _this = this;

		this.props.actions.setNeed2fConfirm(false);

		if (id === true) {
			this.setState({isActiveView: false})
			return;
		}

		var jsonObject = {
			pin: this.props.userReducer.usersPins[id]
		};

		var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

		var data = {
			"ciHsmTokenUri": id,
			"encryptedData": cipherResult.encryptedData,
			"secretKey": cipherResult.secretKey,
			"twoFactorCode": this.props.login.twoFactorCode
		}

		this.props.actions.setShowTokenPopup(false);

		this.props.actions.blockToken(this.props.base.serviceUrl, this.props.login.accessToken, data)
		.then((response) => {
			if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
				this.props.actions.getTokenList(this.props.base.serviceUrl, this.props.login.accessToken)
				.then((response) => {
					this.props.actions.setTokenList(response)

					response.forEach(function (token, index) {
						if (token.uri === id) {
							_this.props.actions.setSelectedToken(index);
						}
					})

					this.setState({numberOfPinInput: 0});
					this.setState({isActiveView: false});

					this.props.actions.setShowActionResult(false)
					this.props.actions.setActionResultData({status: "success", action: "blockToken", message: i18next.t("success")})
                	this.props.actions.setShowActionResult(true)

				});
			} else {
				if (response.json.code === "AUTHCOMMON-14") {
            		var currentCount = _this.state.numberOfPinInput

            		if (currentCount === 0) {
            			currentCount = _this.props.base.numberOfPinInput - 1;
            		} else {
            			currentCount = currentCount - 1;
            		}

            		_this.setState({numberOfPinInput: currentCount});
					_this.setState({needToReEnterPin: true});
            	}

				this.props.actions.setErrorResponse(response.json)
				this.setState({showPopup: true})
			}

			console.log(response);
		});
	}

	handleUnblockToken (id) {
		this.props.actions.setTempId(id);
		var _this = this, currentTime = new Date().getTime() / 1000;
		
		if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		};

		if (Object.keys(this.props.userReducer.usersPins).length === 0 && this.props.userReducer.usersPins.constructor === Object) {
			this.setState({activeHandler: this.handleUnblockToken.bind(this)})
			this.props.actions.setShowTokenPopup(true);
		} else {
			if (this.props.userReducer.usersPins[id] !== undefined && this.props.userReducer.usersPins[id].length > 0) {
				this.props.actions.setShowTokenPopup(false);
				this.setState({isActiveView: true})

				var jsonObject = {
					pin: this.props.userReducer.usersPins[id]
				};

				var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

				var data = {
					"ciHsmTokenUri": id,
					"encryptedData": cipherResult.encryptedData,
					"secretKey": cipherResult.secretKey
				}

				if (this.props.login.is2fEnabled) {
					this.props.actions.unblockToken2f(this.props.base.serviceUrl, this.props.login.accessToken, data)
					.then((response) => {
						if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
							this.setState({confirm2fTitle: "unblockToken2fConfirm"})
							this.setState({active2fBodyHandler: this.handleUnblockToken2f.bind(this)})
							this.setState({id: id});
							this.props.actions.setNeed2fConfirm(true);
						} else {
							this.props.actions.setErrorResponse(response.json)
							this.setState({showPopup: true})
							this.setState({isActiveView: false})
						}

						return;
					})
					
					return;
				}

				this.props.actions.unblockToken(this.props.base.serviceUrl, this.props.login.accessToken, data)
				.then((response) => {
					if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
						this.props.actions.getTokenList(this.props.base.serviceUrl, this.props.login.accessToken)
						.then((response) => {
							this.props.actions.setTokenList(response)
							response.forEach(function (token, index) {
								if (token.uri === id) {
									_this.props.actions.setSelectedToken(index);
								}
							})

							this.props.actions.setActionResultData({status: "success", action: "unblockToken", message: i18next.t("success")})
	                    	this.props.actions.setShowActionResult(true)
						});
					} else {
						var tempData = this.props.userReducer.usersPins;

						delete tempData[id]; 
						this.props.actions.setUsersPins(tempData);

						this.props.actions.setErrorResponse(response.json)
						this.setState({showPopup: true})
					}

					console.log(response);
					
				});
			} else {
				this.props.actions.setShowTokenPopup(true);
				this.setState({activeHandler: this.handleUnblockToken.bind(this)})
			}
		}
	}

	handleUnblockToken2f (id) {
		this.props.actions.setTempId(id);
		var _this = this;

		this.props.actions.setNeed2fConfirm(false);

		if (id === true) {
			this.setState({isActiveView: false})
			return;
		}

		var jsonObject = {
			pin: this.props.userReducer.usersPins[id]
		};

		var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

		var data = {
			"ciHsmTokenUri": id,
			"encryptedData": cipherResult.encryptedData,
			"secretKey": cipherResult.secretKey,
			"twoFactorCode": this.props.login.twoFactorCode
		}

			this.props.actions.unblockToken(this.props.base.serviceUrl, this.props.login.accessToken, data)
			.then((response) => {
				if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
					this.props.actions.getTokenList(this.props.base.serviceUrl, this.props.login.accessToken)
					.then((response) => {
						this.props.actions.setTokenList(response)
						response.forEach(function (token, index) {
							if (token.uri === id) {
								_this.props.actions.setSelectedToken(index);
							}
						})

						this.setState({numberOfPinInput: 0});
						this.setState({isActiveView: false});

						this.props.actions.setActionResultData({status: "success", action: "unblockToken", message: i18next.t("success")})
                    	this.props.actions.setShowActionResult(true)
					});
				} else {
					if (response.json.code === "AUTHCOMMON-14") {
                		var currentCount = _this.state.numberOfPinInput

                		if (currentCount === 0) {
                			currentCount = _this.props.base.numberOfPinInput - 1;
                		} else {
                			currentCount = currentCount - 1;
                		}

                		_this.setState({numberOfPinInput: currentCount});
						_this.setState({needToReEnterPin: true});
                	}

					this.props.actions.setErrorResponse(response.json)
					this.setState({showPopup: true})
				}

				console.log(response);		
		});
	}

	handleRemovePin (id) {
		if (this.props.userReducer.usersPins[id] !== undefined && this.props.userReducer.usersPins[id].length > 0) {
			var tempData = this.props.userReducer.usersPins;

			delete tempData[id]; 
			this.props.actions.setUsersPins(tempData);
		}
	}

	handleRefreshToken (id) {
		var _this = this, currentTime = new Date().getTime() / 1000;
		this.props.actions.setTempId(id);

		if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		}

		if (Object.keys(this.props.userReducer.usersPins).length === 0 && this.props.userReducer.usersPins.constructor === Object) {
			this.setState({activeHandler: this.handleRefreshToken.bind(this)})
			this.props.actions.setShowTokenPopup(true);
		} else {
			if (this.props.userReducer.usersPins[id] !== undefined && this.props.userReducer.usersPins[id].length > 0) {
				this.props.actions.setShowTokenPopup(false);
				this.props.actions.setNeedRefresh(false)

				var jsonObject = {
					pin: this.props.userReducer.usersPins[id]
				};

				var selectedIndex = this.props.tokenList.selectedToken;

				// for testing time
				// console.log("current time: " + Date.now())
				// console.log("delta: " + this.props.login.timeDelta)
				// console.log("time with delta: " + (Date.now() + this.props.login.timeDelta))
				// console.log("time for encrypt: " + new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z")

				// this.props.actions.getPublicKeyCurrentUtcDateTime(this.props.base.serviceUrl, this.props.login.accessToken)
    //             .then((response) => {
    //                 var newDelta = new Date(response.currentUtcDateTime).getTime() - Date.now()
    //                 console.log("new delta: " + newDelta)
    //             })

				var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

				var data = {
					"ciHsmTokenUri": id,
					"encryptedData": cipherResult.encryptedData,
					"secretKey": cipherResult.secretKey
				}

				this.props.actions.getTokenEnquire(this.props.base.serviceUrl, this.props.login.accessToken, data)
				.then((response) => {
					if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
						this.props.actions.clearTokenEnquire();

						var enquires = this.props.tokenList.tokenEnquiresList;
						var list = this.props.tokenList.list

						if (this.props.tokenList.tokenEnquiresList[id] === undefined) {
							enquires[id] = response;
							this.props.actions.setTokenEnquiresList(enquires);

							this.props.tokenList.list.forEach(function (token, index) {
								if (token.uri === id) {
									list[index] = response;
								}

								_this.props.actions.setTokenList(list)
							})

						} else {
							enquires[id] = response;
							this.props.actions.setTokenEnquiresList(enquires);

							this.props.tokenList.list.forEach(function (token, index) {
								if (token.uri === id) {
									list[index] = response;
								}

								_this.props.actions.setTokenList(list)
							})
						}

						this.props.actions.setTokenEnquire(response);
						this.props.actions.setSelectedToken(selectedIndex);

						this.props.actions.setActionResultData({status: "success", action: "refreshTokenData", message: i18next.t("success")});
                		this.props.actions.setShowActionResult(true);

					} else {
						var tempData = this.props.userReducer.usersPins;

						delete tempData[id]; 
						this.props.actions.setUsersPins(tempData);

						this.props.actions.setErrorResponse(response.json)
						this.setState({showPopup: true})
					}
				});
			} else {
				this.props.actions.setShowTokenPopup(true);
				this.setState({activeHandler: this.handleRefreshToken.bind(this)})
			}
		}
	}

	copyToClipboard (uri) {
		var textArea,
        copy;

	    function isOS() {
	        return navigator.userAgent.match(/ipad|iphone/i);
	    }

	    function createTextArea(text) {
	        textArea = document.createElement('textArea');
	        textArea.value = text;
	        textArea.style.cssText = "position: absolute";
	        textArea.style.cssText = "top: -9999px";
	        document.body.appendChild(textArea);
	    }

	    function selectText() {
	        var range,
	            selection;

	        if (isOS()) {
	            range = document.createRange();
	            range.selectNodeContents(textArea);
	            selection = window.getSelection();
	            selection.removeAllRanges();
	            selection.addRange(range);
	            textArea.setSelectionRange(0, 999999);
	        } else {
	            textArea.select();
	        }
	    }

	    function copyToClipboard() {        
	        document.execCommand('copy');
	        textArea.blur();
	        document.body.removeChild(textArea);
	    }

	    copy = function(text) {
	        createTextArea(text);
	        selectText();
	        copyToClipboard();
		};

		copy(uri);
	}

	showGenerateKeyPopup (id) {
		var _this = this, exit = false, currentTime = new Date().getTime() / 1000;
		this.props.actions.setTempId(id);
		
		if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		};

		if ((this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached !== null && (this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.AGREEMENT !== undefined && this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.SIGNATURE !== undefined))) {
			exit = true;
		}

		this.props.tokenList.list.forEach(function (token, index) {
			if (token.uri === id && token.status === "BLOCKED") {
				exit = true;
			}
		})

		if (exit) {
			return;
		}

		if (Object.keys(this.props.userReducer.usersPins).length === 0 && this.props.userReducer.usersPins.constructor === Object) {
			this.setState({activeHandler: this.showGenerateKeyPopup.bind(_this)})
			this.props.actions.setShowTokenPopup(true);
		} else {
			if (this.props.userReducer.usersPins[id] !== undefined && this.props.userReducer.usersPins[id].length > 0) {
				this.props.actions.setShowTokenPopup(false);

				this.props.actions.setShowGenerateKeyPopup(true);
			} else {
				this.props.actions.setShowTokenPopup(true);
				this.setState({activeHandler: this.showGenerateKeyPopup.bind(this)})
			}
		}
	}

	showChangePinPopup (id) {
		var _this = this, exit = false, currentTime = new Date().getTime() / 1000;
		this.props.actions.setTempId(id);

		if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		};

		this.props.tokenList.list.forEach(function (token, index) {
			if (token.uri === id && token.status === "BLOCKED") {
				exit = true;
			}
		})

		if (exit) {
			return;
		}

		if (Object.keys(this.props.userReducer.usersPins).length === 0 && this.props.userReducer.usersPins.constructor === Object) {
			this.setState({activeHandler: this.showChangePinPopup.bind(_this)})
			this.props.actions.setShowTokenPopup(true);
		} else {
			if (this.props.userReducer.usersPins[id] !== undefined && this.props.userReducer.usersPins[id].length > 0) {
				this.props.actions.setShowTokenPopup(false);
				this.props.actions.setOldPin("")
				this.props.actions.setNewPin("")

				this.props.actions.setShowChangePinPopup(true);
			} else {
				this.props.actions.setShowTokenPopup(true);
				this.setState({activeHandler: this.showChangePinPopup.bind(this)})
			}
		}
	}

	showChangePinByPukPopup (id) {
		var exit = false, currentTime = new Date().getTime() / 1000;
		this.props.actions.setTempId(id);

		if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		};

		this.props.tokenList.list.forEach(function (token, index) {
			if (token.uri === id && token.status === "BLOCKED") {
				exit = true;
			}
		})

		if (exit) {
			return;
		}

		this.props.actions.setPuk("")
		this.props.actions.setNewPin("")

		this.props.actions.setShowChangePinByPukPopup(true);
	}

	generateKey2f (id) {
		var _this = this, jsonObject, cipherResult, data;

		var pkcs10 = {};

		this.props.actions.setNeed2fConfirm(false);

		if (id === true) {
			this.setState({isActiveView: false})
			this.props.actions.setGlobalProcessing(false);
			this.props.actions.setIsElectronicStamp(false);
			this.props.actions.setCertificateAutoIssuing(false);
			return;
		}

		this.props.actions.setKeyGenerationProcess("processing")
		this.props.actions.setCertificateIssuingProcess("waiting")
		this.props.actions.setCertificateImportProcess("waiting")

		this.props.actions.setTempId(id);

		var utf8encode = function(unicodeString) {
			if (typeof unicodeString != 'string') throw new TypeError('parameter ‘unicodeString’ is not a string');
		    const utf8String = unicodeString.replace(
		        /[\u0080-\u07ff]/g,
		        function(c) {
		            var cc = c.charCodeAt(0);
		            // eslint-disable-next-line
		            return String.fromCharCode(0xc0 | cc>>6, 0x80 | cc&0x3f); }
		    ).replace(
		        /[\u0800-\uffff]/g,
		        function(c) {
		            var cc = c.charCodeAt(0);
		            // eslint-disable-next-line
		            return String.fromCharCode(0xe0 | cc>>12, 0x80 | cc>>6&0x3F, 0x80 | cc&0x3f); }
		    );
		    return utf8String;
	    }

		Object.keys(this.props.profile.fields).map((key, index) => {
			if(!this.props.profile.fields[key].optional || (this.props.keyGen[this.props.profile.fields[key].name] !== undefined && this.props.keyGen[this.props.profile.fields[key].name].length > 0)) {
				if (_this.props.keyGen[_this.props.profile.fields[key].name] !== undefined && _this.props.keyGen[_this.props.profile.fields[key].name].length > 0) {
					pkcs10[_this.props.profile.fields[key].name] = utf8encode(_this.props.keyGen[_this.props.profile.fields[key].name]) || ""
				}
				
			}

			return pkcs10;
		})

		console.log(pkcs10)

		jsonObject = {
			"pin": this.props.userReducer.usersPins[id],
			"isElectronicStamp": this.props.profile.isElectronicStamp,
			"dataForPkcs10": pkcs10
		};

		cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

		data = {
			"ciHsmTokenUri": id,
			"certificateProfileId": this.props.profile.profilesList[this.props.profile.selectedProfile].id,
			"generatedKeysType": this.props.profile.generatedKeysType,
			"encryptedData": cipherResult.encryptedData,
			"secretKey": cipherResult.secretKey,
			"twoFactorCode": this.props.login.twoFactorCode
		}

		console.log(data)

		_this.props.actions.generateKeys(_this.props.base.serviceUrl, data, _this.props.login.accessToken)
		.then((response) => {
			if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
				_this.setState({keyIdentifier: response.keyIdentifier})
				_this.setState({certificateBase64Data: response.certificateBase64Data})
				_this.props.actions.setKeyGenerationProcess("done")
				_this.forceUpdate();
				_this.setState({numberOfPinInput: 0});

            	if (!this.props.profile.certificateAutoIssuing) {
            		if (this.state.needToGenerateSecondKey === false && this.props.profile.keyTypeSignatureSetted && this.props.profile.keyTypeAgreementSetted) {
            			this.setState({needToGenerateSecondKey: true})
						this.generateKey(id)
					} else {
						this.setState({needToGenerateSecondKey: false})
						this.props.actions.setGlobalProcessing(false);
	            		_this.setState({isActiveView: false})
	            		_this.handleRefreshToken(id);
					}
            	} else {
            		if (this.props.login.is2fEnabled) {
            			jsonObject = {
							"pin": this.props.userReducer.usersPins[id]
						};

						cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

            			data = {
							"ciHsmTokenUri": id,
							"certificateProfileId": this.props.profile.profilesList[this.props.profile.selectedProfile].id,
							"generatedKeysType": this.props.profile.generatedKeysType,
							"keyIdentifier": this.state.keyIdentifier,
							"encryptedData": cipherResult.encryptedData,
							"secretKey": cipherResult.secretKey
						}

            			this.props.actions.certificateIssue2f(this.props.base.serviceUrl, data, this.props.login.accessToken)
						.then((response) => {
							if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
								this.setState({confirm2fTitle: "certIssuingConfirmTitle"})
		            			this.setState({active2fBodyHandler: this.certificateIssue2f.bind(this)})
		            			this.setState({id: id});
								this.props.actions.setNeed2fConfirm(true);
							} else {
								_this.props.actions.setGlobalProcessing(false);
				            	_this.props.actions.setErrorResponse(response.json)
								_this.setState({showPopup: true})
								_this.setState({isActiveView: false})
								_this.props.actions.setKeyGenerationProcess("waiting")
								_this.props.actions.setCertificateIssuingProcess("waiting")
								_this.props.actions.setCertificateImportProcess("waiting")
								_this.props.actions.setIsElectronicStamp(false);
								_this.props.actions.setCertificateAutoIssuing(false);
							}

							return;
						})
            			
						return;
					}
            	}

            } else {
            	if (response.json.code === "AUTHCOMMON-14") {
            		var currentCount = _this.state.numberOfPinInput

            		if (currentCount === 0) {
            			currentCount = _this.props.base.numberOfPinInput - 1;
            		} else {
            			currentCount = currentCount - 1;
            		}

            		_this.setState({numberOfPinInput: currentCount});
					_this.setState({needToReEnterPin: true});
					_this.props.actions.setErrorResponse(response.json)
					_this.setState({showPopup: true})
            	} else {
            		_this.props.actions.setGlobalProcessing(false);
					_this.props.actions.setErrorResponse(response.json)
					_this.setState({showPopup: true})
					_this.setState({isActiveView: false})
					_this.props.actions.setIsElectronicStamp(false);
					_this.props.actions.setCertificateAutoIssuing(false);
            	}
            }
		})
	}

	certificateIssue2f (id) {
		var data, jsonObject, cipherResult, _this = this;

		this.props.actions.setNeed2fConfirm(false);
		this.setState({isActiveView: true})

		if (id === true) {
			this.setState({isActiveView: false})
			this.props.actions.setGlobalProcessing(false);
			this.props.actions.setIsElectronicStamp(false);
			this.props.actions.setCertificateAutoIssuing(false);
			return;
		}

		jsonObject = {
			"pin": this.props.userReducer.usersPins[id]
		};

		cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

		data = {
			"ciHsmTokenUri": id,
			"certificateProfileId": this.props.profile.profilesList[this.props.profile.selectedProfile].id,
			"cipId": this.props.profile.allowedRegistarCipIdList[this.props.profile.selectedRegistarCipId].id,
			"keyIdentifier": this.state.keyIdentifier,
			"encryptedData": cipherResult.encryptedData,
			"secretKey": cipherResult.secretKey,
			"twoFactorCode": this.props.login.twoFactorCode
		}

		console.log(data);

		this.props.actions.setCertificateIssuingProcess("processing")	

		this.props.actions.certificateIssue(this.props.base.serviceUrl, data, this.props.login.accessToken)
		.then((response) => {
			if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
				_this.props.actions.setCertificateIssuingProcess("done")

            	jsonObject = {
					pin: this.props.userReducer.usersPins[id],
					certificateData: response.certificateBase64Data
				};

				cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

				data = {
					"ciHsmTokenUri": id,
					"encryptedData": cipherResult.encryptedData,
					"secretKey": cipherResult.secretKey
				}

				this.props.actions.setCertificateImportProcess("processing")

				this.props.actions.importCertificate(this.props.base.serviceUrl, this.props.login.accessToken, data)
				.then((response) => {
					if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
						_this.props.actions.setCertificateImportProcess("done")

						if (_this.state.needToGenerateSecondKey === false && _this.props.profile.keyTypeSignatureSetted && _this.props.profile.keyTypeAgreementSetted) {
	            			_this.setState({needToGenerateSecondKey: true})
							_this.generateKey(id)
							return;
						} else {
							_this.setState({needToGenerateSecondKey: false})
							_this.props.actions.setGlobalProcessing(false);
		                	_this.handleRefreshToken(id);
		                	_this.setState({isActiveView: false})
		                	_this.setState({numberOfPinInput: 0});
						}
	                	
					} else {
						_this.props.actions.setGlobalProcessing(false);
						_this.props.actions.setErrorResponse(response.json)
						_this.setState({showPopup: true})
					}

					_this.props.actions.clearCertificateData();
					_this.props.actions.clearCertificateFileName();
					_this.props.actions.setKeyGenerationProcess("waiting")
					_this.props.actions.setCertificateIssuingProcess("waiting")
					_this.props.actions.setCertificateImportProcess("waiting")
					_this.props.actions.setIsElectronicStamp(false);
					_this.props.actions.setCertificateAutoIssuing(false);

					console.log(response);
				});

            } else {
            	if (response.json.code === "AUTHCOMMON-14") {
            		var currentCount = _this.state.numberOfPinInput

            		if (currentCount === 0) {
            			currentCount = _this.props.base.numberOfPinInput - 1;
            		} else {
            			currentCount = currentCount - 1;
            		}

            		_this.setState({numberOfPinInput: currentCount});
					_this.setState({needToReEnterPin: true});
            	} else {
            		_this.props.actions.setKeyGenerationProcess("waiting")
					_this.props.actions.setCertificateIssuingProcess("waiting")
					_this.props.actions.setCertificateImportProcess("waiting")
					_this.props.actions.setIsElectronicStamp(false);
					_this.props.actions.setCertificateAutoIssuing(false);
					_this.props.actions.setGlobalProcessing(false);
					_this.setState({isActiveView: true})
            	}

        		// _this.props.actions.setGlobalProcessing(false);
            	_this.props.actions.setErrorResponse(response.json)
				_this.setState({showPopup: true})
				
            }
			console.log(response);
		})
	}

	changePin (id) {
		var tempData, currentTime = new Date().getTime() / 1000;

		this.props.actions.setTempId(id);
		this.props.actions.setShowChangePinPopup(false);

		if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		};

		if (Object.keys(this.props.userReducer.usersPins).length === 0 && this.props.userReducer.usersPins.constructor === Object) {
			this.setState({activeHandler: this.changePin.bind(this)})
			this.props.actions.setShowTokenPopup(true);
		} else {
			if (this.props.userReducer.usersPins[id] !== undefined && this.props.userReducer.usersPins[id].length > 0) {
				this.props.actions.setShowTokenPopup(false);
				this.setState({isActiveView: true})

				if (this.props.login.is2fEnabled) {
					this.setState({confirm2fTitle: "changePin2fConfirm"})
					this.setState({active2fBodyHandler: this.changePin2f.bind(this)})
					this.setState({id: id});
					this.props.actions.setNeed2fConfirm(true);
					return;
				}

				var jsonObject = {
					pin: this.props.userReducer.oldPin,
					newPin: this.props.userReducer.newPin
				};

				var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

				var data = {
					"ciHsmTokenUri": id,
					"encryptedData": cipherResult.encryptedData,
					"secretKey": cipherResult.secretKey
				}

				this.props.actions.changePinByPin(this.props.base.serviceUrl, this.props.login.accessToken, data)
				.then((response) => {
					if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
						tempData = this.props.userReducer.usersPins;
						delete tempData[id];
						this.props.actions.setUsersPins(tempData);

						this.props.actions.setOldPin("")
					    this.props.actions.setNewPin("")

						this.props.actions.setActionResultData({status: "success", action: "changePinTitle", message: i18next.t("success")})
	                    this.props.actions.setShowActionResult(true)
					} else {
						tempData = this.props.userReducer.usersPins;

						this.props.actions.setOldPin("")
					    this.props.actions.setNewPin("")

						delete tempData[id]; 
						this.props.actions.setUsersPins(tempData);

						this.props.actions.setErrorResponse(response.json)
						this.setState({showPopup: true})
					}

					console.log(response);
					
				});
			} else {
				this.props.actions.setShowTokenPopup(true);
				this.setState({activeHandler: this.changePin.bind(this)})
			}
		}
	}

	changePin2f (id) {
		this.props.actions.setTempId(id);
		var _this = this, tempData;

		this.props.actions.setNeed2fConfirm(false);

		if (id === true) {
			this.setState({isActiveView: false})
			return;
		}

		var jsonObject = {
			pin: this.props.userReducer.oldPin,
			newPin: this.props.userReducer.newPin
		};

		var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

		var data = {
			"ciHsmTokenUri": id,
			"encryptedData": cipherResult.encryptedData,
			"secretKey": cipherResult.secretKey,
			"twoFactorCode": this.props.login.twoFactorCode
		}

		this.props.actions.changePinByPin2f(this.props.base.serviceUrl, this.props.login.accessToken, data)
		.then((response) => {
			if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {

				this.props.actions.changePinByPin(this.props.base.serviceUrl, this.props.login.accessToken, data)
				.then((response) => {
					if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
						var tempData = this.props.userReducer.usersPins;
						delete tempData[id];
						this.props.actions.setUsersPins(tempData);

						this.setState({numberOfPinInput: 0});
						this.setState({isActiveView: false});

						this.props.actions.setOldPin("")
					    this.props.actions.setNewPin("")

						this.props.actions.setActionResultData({status: "success", action: "changePinTitle", message: i18next.t("success")})
                    	this.props.actions.setShowActionResult(true)
					} else {
						if (response.json.code === "AUTHCOMMON-14") {
	                		var currentCount = _this.state.numberOfPinInput

	                		if (currentCount === 0) {
	                			currentCount = _this.props.base.numberOfPinInput - 1;
	                		} else {
	                			currentCount = currentCount - 1;
	                		}

	                		_this.setState({numberOfPinInput: currentCount});
							_this.setState({needToReEnterPin: true});
	                	}

	                	this.props.actions.setOldPin("")
					    this.props.actions.setNewPin("")

						this.props.actions.setErrorResponse(response.json)
						this.setState({showPopup: true})
					}

					console.log(response);
					
				});
			} else {
				tempData = this.props.userReducer.usersPins;

				delete tempData[id]; 
				this.props.actions.setUsersPins(tempData);

				this.props.actions.setErrorResponse(response.json)
				this.setState({showPopup: true})
				this.setState({isActiveView: false})
			}

			console.log(response);
			
		});
	}

	generateKey (id) {
		var keyType;
		this.props.actions.setTempId(id);
		var _this = this, jsonObject, cipherResult, data, currentTime = new Date().getTime() / 1000;
		
		if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		};

		this.props.actions.setShowGenerateKeyPopup(false);

		if (Object.keys(this.props.userReducer.usersPins).length === 0 && this.props.userReducer.usersPins.constructor === Object) {
			this.setState({activeHandler: this.showGenerateKeyPopup.bind(this)})
			this.props.actions.setShowTokenPopup(true);
		} else {
			if (this.props.userReducer.usersPins[id] !== undefined && this.props.userReducer.usersPins[id].length > 0) {
				this.props.actions.setShowTokenPopup(false);
				this.setState({isActiveView: true})
				this.props.actions.setGlobalProcessing(true);

				var pkcs10 = {};

				var utf8encode = function(unicodeString) {
					if (typeof unicodeString != 'string') throw new TypeError('parameter ‘unicodeString’ is not a string');
				    const utf8String = unicodeString.replace(
				        /[\u0080-\u07ff]/g,
				        function(c) {
				            var cc = c.charCodeAt(0);
				            // eslint-disable-next-line
				            return String.fromCharCode(0xc0 | cc >> 6, 0x80 | cc & 0x3f); }
				    ).replace(
				        /[\u0800-\uffff]/g,
				        function(c) {
				            var cc = c.charCodeAt(0);
				            // eslint-disable-next-line
				            return String.fromCharCode(0xe0 | cc >> 12, 0x80 | cc >> 6 & 0x3F, 0x80 | cc & 0x3f); }
				    );
				    return utf8String;
			    }

				Object.keys(this.props.profile.fields).map((key, index) => {
					if(!this.props.profile.fields[key].optional || (this.props.keyGen[this.props.profile.fields[key].name] !== undefined && this.props.keyGen[this.props.profile.fields[key].name].length > 0)) {
						if (_this.props.keyGen[_this.props.profile.fields[key].name] !== undefined && _this.props.keyGen[_this.props.profile.fields[key].name].length > 0) {
							pkcs10[_this.props.profile.fields[key].name] = utf8encode(_this.props.keyGen[_this.props.profile.fields[key].name]) || ""
						}
						
					}

					return pkcs10;
				})

				console.log(pkcs10)

				if (_this.props.profile.isPrivateCert) {
					jsonObject = {
						"pin": this.props.userReducer.usersPins[id],
						"isElectronicStamp": this.props.profile.isElectronicStamp,
						"dataForPkcs10": pkcs10,
						"certificateAccessSpecifier": "PRIVATE"
					};
				} else {
					jsonObject = {
						"pin": this.props.userReducer.usersPins[id],
						"isElectronicStamp": this.props.profile.isElectronicStamp,
						"dataForPkcs10": pkcs10
					};
				}

				// var selectedIndex = this.props.tokenList.selectedToken;

				cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

				if (this.props.profile.keyTypeSignatureSetted && this.props.profile.keyTypeAgreementSetted) {
					if (this.props.profile.generatedKeysType === "SIGNATURE") {
						this.props.actions.setGeneratedKeysType("AGREEMENT")

						keyType = "AGREEMENT"
					} else {
						this.props.actions.setGeneratedKeysType("SIGNATURE")
						keyType = "SIGNATURE"
					}
				} else {
					if (this.props.profile.keyTypeSignatureSetted) {
						this.props.actions.setGeneratedKeysType("SIGNATURE")
						keyType = "SIGNATURE"
					} else {
						this.props.actions.setGeneratedKeysType("AGREEMENT")
						keyType = "AGREEMENT"
					}
				}

				data = {
					"ciHsmTokenUri": id,
					"certificateProfileId": this.props.profile.profilesList[this.props.profile.selectedProfile].id,
					"generatedKeysType": keyType,
					"encryptedData": cipherResult.encryptedData,
					"secretKey": cipherResult.secretKey
				}

				console.log(data)

				if (this.props.login.is2fEnabled) {
					this.props.actions.generateKeys2f(this.props.base.serviceUrl, data, this.props.login.accessToken)
					.then((response) => {
						if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
							this.setState({confirm2fTitle: "keyGenenerationConfirmTitle"})
							this.setState({active2fBodyHandler: this.generateKey2f.bind(this)})
							this.setState({id: id});
							this.props.actions.setKeyGenerationProcess("waiting")
							this.props.actions.setCertificateIssuingProcess("waiting")
							this.props.actions.setCertificateImportProcess("waiting")
							this.props.actions.setNeed2fConfirm(true);
						} else {
							_this.props.actions.setGlobalProcessing(false);
							_this.props.actions.setErrorResponse(response.json)
							_this.setState({showPopup: true})
							_this.props.actions.setIsElectronicStamp(false);
							_this.props.actions.setCertificateAutoIssuing(false);
						}

						return;
					})
					
					return;
				}

				this.props.actions.setKeyGenerationProcess("processing")
				this.props.actions.setCertificateIssuingProcess("waiting")
				this.props.actions.setCertificateImportProcess("waiting")

				this.props.actions.generateKeys(this.props.base.serviceUrl, data, this.props.login.accessToken)
				.then((response) => {
					if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
						_this.props.actions.setKeyGenerationProcess("done")
						_this.forceUpdate();

	                	if (!this.props.profile.certificateAutoIssuing) {
	                		if (this.state.needToGenerateSecondKey === false && this.props.profile.keyTypeSignatureSetted && this.props.profile.keyTypeAgreementSetted) {
		            			this.setState({needToGenerateSecondKey: true})
								this.generateKey(id)
							} else {
								this.setState({needToGenerateSecondKey: false})
								this.props.actions.setGlobalProcessing(false);
			            		_this.setState({isActiveView: false})
			            		_this.handleRefreshToken(id);
							}
	                	} else {

	                		jsonObject = {
								"pin": this.props.userReducer.usersPins[id]
							};

							cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

							var keyIdentifier = response.keyIdentifier
							this.setState({keyIdentifier: response.keyIdentifier})
							this.setState({certificateBase64Data: response.certificateBase64Data})
							console.log(keyIdentifier);

							data = {
								"ciHsmTokenUri": id,
								"certificateProfileId": this.props.profile.profilesList[this.props.profile.selectedProfile].id,
								"cipId": this.props.profile.allowedRegistarCipIdList[this.props.profile.selectedRegistarCipId].id,
    							"keyIdentifier": keyIdentifier,
								"encryptedData": cipherResult.encryptedData,
								"secretKey": cipherResult.secretKey
							}

							this.props.actions.setCertificateIssuingProcess("processing")

	                		this.props.actions.certificateIssue(this.props.base.serviceUrl, data, this.props.login.accessToken)
	                		.then((response) => {
	                			if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
	                				_this.props.actions.setCertificateIssuingProcess("done")

				                	jsonObject = {
										pin: this.props.userReducer.usersPins[id],
										certificateData: response.certificateBase64Data
									};

									cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

									data = {
										"ciHsmTokenUri": id,
										"encryptedData": cipherResult.encryptedData,
										"secretKey": cipherResult.secretKey
									}

									this.props.actions.setCertificateImportProcess("processing")

									this.props.actions.importCertificate(this.props.base.serviceUrl, this.props.login.accessToken, data)
									.then((response) => {
										if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
											_this.props.actions.setCertificateImportProcess("done")

						                	if (_this.state.needToGenerateSecondKey === false && _this.props.profile.keyTypeSignatureSetted && _this.props.profile.keyTypeAgreementSetted) {
						            			_this.setState({needToGenerateSecondKey: true})
												_this.generateKey(id)
											} else {
												_this.setState({needToGenerateSecondKey: false})
												_this.props.actions.setGlobalProcessing(false);
							            		_this.setState({isActiveView: false})
							            		_this.handleRefreshToken(id);
											}
										} else {
											_this.props.actions.setGlobalProcessing(false);
											_this.props.actions.setErrorResponse(response.json)
											_this.setState({showPopup: true})
										}

										this.props.actions.clearCertificateData();
										this.props.actions.clearCertificateFileName();
										_this.props.actions.setKeyGenerationProcess("processing")
										_this.props.actions.setCertificateIssuingProcess("processing")
										_this.props.actions.setCertificateImportProcess("processing")

										console.log(response);
									});

				                } else {
				                	_this.props.actions.setGlobalProcessing(false);
				                	_this.props.actions.setErrorResponse(response.json)
									_this.setState({showPopup: true})
									_this.props.actions.setKeyGenerationProcess("processing")
									_this.props.actions.setCertificateIssuingProcess("processing")
									_this.props.actions.setCertificateImportProcess("processing")
									_this.props.actions.setIsElectronicStamp(false);
									_this.props.actions.setCertificateAutoIssuing(false);
				                }
	                			console.log(response);
	                		})
	                	}
					} else {
						_this.props.actions.setGlobalProcessing(false);
						_this.props.actions.setErrorResponse(response.json)
						_this.setState({showPopup: true})
						_this.props.actions.setIsElectronicStamp(false);
						_this.props.actions.setCertificateAutoIssuing(false);
					}
					console.log(response);

					
				})

			} else {
				this.props.actions.setShowTokenPopup(true);
				this.setState({activeHandler: this.showGenerateKeyPopup.bind(this)})
			}
		}
	}

	changePinByPuk (id) {
		var currentTime = new Date().getTime() / 1000;

		this.props.actions.setTempId(id);
		this.props.actions.setShowChangePinByPukPopup(false);

		if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		};

		var jsonObject = {
			puk: this.props.userReducer.puk,
			newPin: this.props.userReducer.newPin
		};

		var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

		var data = {
			"ciHsmTokenUri": id,
			"encryptedData": cipherResult.encryptedData,
			"secretKey": cipherResult.secretKey
		}

		this.setState({isActiveView: true})

		if (this.props.login.is2fEnabled) {
			this.props.actions.changePinByPuk2f(this.props.base.serviceUrl, this.props.login.accessToken, data)
			.then((response) => {
				if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
					this.setState({confirm2fTitle: "changePinByPuk2fConfirm"})
					this.setState({active2fBodyHandler: this.changePinByPuk2f.bind(this)})
					this.setState({id: id});
					this.props.actions.setNeed2fConfirm(true);
				} else {
					this.props.actions.setErrorResponse(response.json)
					this.setState({showPopup: true})
					this.setState({isActiveView: false})
				}

				return;
			});
			
			return;
		}

		this.props.actions.changePinByPuk(this.props.base.serviceUrl, this.props.login.accessToken, data)
		.then((response) => {
			if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
				var tempData = this.props.userReducer.usersPins;
				delete tempData[id];
				this.props.actions.setUsersPins(tempData);

				this.props.actions.setPuk("")
			    this.props.actions.setNewPin("")

				this.props.actions.setActionResultData({status: "success", action: "restorePinTitle", message: i18next.t("success")})
                this.props.actions.setShowActionResult(true)
			} else {
				tempData = this.props.userReducer.usersPins;

				this.props.actions.setPuk("")
			    this.props.actions.setNewPin("")

				delete tempData[id]; 
				this.props.actions.setUsersPins(tempData);

				this.props.actions.setErrorResponse(response.json)
				this.setState({showPopup: true})
			}

			console.log(response);
			
		});

	}

	changePinByPuk2f (id) {
		this.props.actions.setTempId(id);
		var _this = this;

		this.props.actions.setNeed2fConfirm(false);

		if (id === true) {
			this.setState({isActiveView: false})
			return;
		}

		var jsonObject = {
			puk: this.props.userReducer.puk,
			newPin: this.props.userReducer.newPin
		};

		var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

		var data = {
			"ciHsmTokenUri": id,
			"encryptedData": cipherResult.encryptedData,
			"secretKey": cipherResult.secretKey,
			"twoFactorCode": this.props.login.twoFactorCode
		}

		this.props.actions.changePinByPuk(this.props.base.serviceUrl, this.props.login.accessToken, data)
		.then((response) => {
			if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
				var tempData = this.props.userReducer.usersPins;
				delete tempData[id];
				this.props.actions.setUsersPins(tempData);

				this.setState({numberOfPinInput: 0});
				this.setState({isActiveView: false});

				this.props.actions.setPuk("")
			    this.props.actions.setNewPin("")

				this.props.actions.setActionResultData({status: "success", action: "restorePinTitle", message: i18next.t("success")})
            	this.props.actions.setShowActionResult(true)
			} else {
				if (response.json.code === "AUTHCOMMON-14") {
            		var currentCount = _this.state.numberOfPinInput

            		if (currentCount === 0) {
            			currentCount = _this.props.base.numberOfPinInput - 1;
            		} else {
            			currentCount = currentCount - 1;
            		}

            		_this.setState({numberOfPinInput: currentCount});
					_this.setState({needToReEnterPin: true});
            	}

            	this.props.actions.setPuk("")
			    this.props.actions.setNewPin("")

				this.props.actions.setErrorResponse(response.json)
				this.setState({showPopup: true})
			}

			console.log(response);
			
		});
	}

	showImportCertificatePopup (id) {
		var _this = this, exit = false, currentTime = new Date().getTime() / 1000;

		this.props.actions.setTempId(id);

		if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		};

		this.props.tokenList.list.forEach(function (token, index) {
			if (token.uri === id && token.status === "BLOCKED") {
				exit = true;
			}
		})

		if (exit) {
			return;
		}

		if (Object.keys(this.props.userReducer.usersPins).length === 0 && this.props.userReducer.usersPins.constructor === Object) {
			this.setState({activeHandler: this.showImportCertificatePopup.bind(_this)})
			this.props.actions.setShowTokenPopup(true);
		} else {
			if (this.props.userReducer.usersPins[id] !== undefined && this.props.userReducer.usersPins[id].length > 0) {
				this.props.actions.setShowTokenPopup(false);

				this.props.actions.setShowImportCertificatePopup(true);
			} else {
				this.props.actions.setShowTokenPopup(true);
				this.setState({activeHandler: this.showImportCertificatePopup.bind(this)})
			}
		}
	}

	importCertificate (id) {
		this.props.actions.setTempId(id);
		var _this = this;

		this.props.actions.setShowImportCertificatePopup(false);

		if (Object.keys(this.props.userReducer.usersPins).length === 0 && this.props.userReducer.usersPins.constructor === Object) {
			this.setState({activeHandler: this.showImportCertificatePopup.bind(this)})
			this.props.actions.setShowTokenPopup(true);
		} else {
			if (this.props.userReducer.usersPins[id] !== undefined && this.props.userReducer.usersPins[id].length > 0) {
				this.props.actions.setShowTokenPopup(false);

				if (this.props.userReducer.certificateData === null) {
					this.props.actions.setShowImportCertificatePopup(true);
					return;
				}

				var jsonObject = {
					pin: this.props.userReducer.usersPins[id],
					certificateData: this.props.userReducer.certificateData
				};

				// var selectedIndex = this.props.tokenList.selectedToken;

				var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

				var data = {
					"ciHsmTokenUri": id,
					"encryptedData": cipherResult.encryptedData,
					"secretKey": cipherResult.secretKey
				}

				this.props.actions.importCertificate(this.props.base.serviceUrl, this.props.login.accessToken, data)
				.then((response) => {
					if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
						_this.props.actions.setShowActionResult(false)
						_this.props.actions.setActionResultData({status: "success", action: "importCertificate", message: i18next.t("success")});
	                	_this.props.actions.setShowActionResult(true);
	                	_this.handleRefreshToken(id);
					} else {
						_this.props.actions.setErrorResponse(response.json)
						_this.setState({showPopup: true})
					}

					this.props.actions.clearCertificateData();
					this.props.actions.clearCertificateFileName();

					console.log(response);
				});
			} else {
				this.props.actions.setShowTokenPopup(true);
				this.setState({activeHandler: this.showImportCertificatePopup.bind(this)})
			}
		}
	}

	showMessagePopup () {
		if (this.state.showPopup === true) {
			this.props.actions.setShowError(false)
		}

		if (this.state.showPopup === true && this.state.needToReEnterPin === true) {
			if (this.state.numberOfPinInput > 0) {
				this.props.actions.setNeed2fConfirm(true);
			} else {
				this.props.actions.setGlobalProcessing(false);
				this.setState({needToReEnterPin: false});
				this.setState({numberOfPinInput: 0});
			}
		}

    	this.setState({
          	showPopup: !this.state.showPopup
      	});

      	if (this.props.userReducer.errorResponse.code === "CIHSMVTMSBASE-3") {
			this.props.actions.getTokenList(this.props.base.serviceUrl, this.props.login.accessToken)
			.then((response) => {

				if (response.contentType !== undefined && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
	                this.props.actions.setErrorResponse(response.json)
					this.setState({showErrorPopup: true})
	                return
	            }

				this.props.actions.setTokenList(response)

				console.log(response)

				if (response.length > 0) {
					// this.props.actions.setTokenEnquire(response[0]);
					this.props.actions.setSelectedToken(this.props.tokenList.selectedToken)
	                this.props.actions.clearTokenEnquire();

	                var enquires = this.props.tokenList.tokenEnquiresList;
	                enquires[response[this.props.tokenList.selectedToken].uri] = response[this.props.tokenList.selectedToken];

	                if (response[this.props.tokenList.selectedToken].status === "RESET_BY_SO") {
	                    this.props.actions.setShowResetPopup(true);
	                }

	                if (response[this.props.tokenList.selectedToken].tokenContentDescriptorCached !== null){
	                    this.props.actions.setTokenEnquiresList(enquires);
	                    this.props.actions.setTokenEnquire(response[this.props.tokenList.selectedToken]);
	                }
				}
			});
		}
  	}

  	closeTwoFactorContainer () {
  		this.props.actions.setShowTwoFactorSettings(false);
  	}

  	deleteToken (id) {
  		var exit = false, _this = this, currentTime = new Date().getTime() / 1000;

		this.props.actions.setTempId(id);

		if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		};

		this.props.tokenList.list.forEach(function (token, index) {
			if (token.uri === id && token.status === "BLOCKED") {
				exit = true;
			}
		})

		function checkProperties(obj) {
		    for (var key in obj) {
		        if (obj[key] !== null && Object.keys(obj[key]).length !== 0 && obj[key].constructor === Object)
		            return true;
		    }

		    if (_this.props.userReducer.usersPins[id] === undefined) {
		    	return true;
		    }

		    return false;
		}

		console.log(checkProperties(this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached))
		exit = checkProperties(this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached)
		
		if (this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached !== null) {
			if (this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.keyIdentifiers.length > 0 || this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.pendingKeyIdentifiers.length > 0) {
				if (this.props.tokenList.list[this.props.tokenList.selectedToken].owner.id === this.props.login.userData.userName) {
					exit = true;
				} else {
					exit = false;
				}
			}
		} else {
			exit = false;
		}

		if (this.props.userReducer.userPuk.length === 0) {
			this.props.actions.setShowTokenPukPopup(true);
			exit = true;
		}

		if (exit) {
			return;
		}
  	}

  	handleDeleteToken (id) {
  		var exit = false, _this = this, currentTime = new Date().getTime() / 1000;

		this.props.actions.setTempId(id);

		if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		};

		this.props.tokenList.list.forEach(function (token, index) {
			if (token.uri === id && token.status === "BLOCKED") {
				exit = true;
			}
		})

		function checkProperties(obj) {
		    for (var key in obj) {
		        if (obj[key] !== null && Object.keys(obj[key]).length !== 0 && obj[key].constructor === Object)
		            return true;
		    }

		    if (_this.props.userReducer.usersPins[id] === undefined) {
		    	return true;
		    }

		    return false;
		}

		console.log(checkProperties(this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached))
		exit = checkProperties(this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached)
		
		if (this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached !== null) {
			if (this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.keyIdentifiers.length > 0 || this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.pendingKeyIdentifiers.length > 0) {
				if (this.props.tokenList.list[this.props.tokenList.selectedToken].owner.id === this.props.login.userData.userName) {
					exit = true;
				} else {
					exit = false;
				}
			}
		} else {
			exit = false;
		}

		// if (this.props.userReducer.userPuk.length === 0) {
		// 	this.props.actions.setShowTokenPukPopup(true);
		// 	exit = true;
		// }

		if (exit) {
			return;
		}

		if (Object.keys(this.props.userReducer.usersPins).length === 0 && this.props.userReducer.usersPins.constructor === Object) {
			this.setState({activeHandler: this.handleUnblockToken.bind(this)})
			this.props.actions.setShowTokenPopup(true);
		} else {
			if (this.props.userReducer.usersPins[id] !== undefined && this.props.userReducer.usersPins[id].length > 0) {
				this.props.actions.setShowTokenPopup(false);
				this.setState({isActiveView: true})

				var jsonObject = {
					puk: this.props.userReducer.userPuk
				};

				var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

				var data = {
					"ciHsmTokenUri": id,
					"encryptedData": cipherResult.encryptedData,
					"secretKey": cipherResult.secretKey
				}

				if (this.props.login.is2fEnabled) {
					this.props.actions.removeToken2f(this.props.base.serviceUrl, this.props.login.accessToken, data)
					.then((response) => {
						if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
							this.setState({confirm2fTitle: "deleteToken2fConfirm"})
							this.setState({active2fBodyHandler: this.handleDeleteToken2f.bind(this)})
							this.setState({id: id});
							this.props.actions.setNeed2fConfirm(true);
						} else {
							this.props.actions.setErrorResponse(response.json)
							this.setState({showPopup: true})
							this.setState({isActiveView: false})
						}

						return;
					})
					
					return;
				}

				this.props.actions.removeToken(this.props.base.serviceUrl, this.props.login.accessToken, data)
				.then((response) => {
					if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
						_this.props.actions.getTokenList(_this.props.base.serviceUrl, _this.props.login.accessToken)
						.then((response) => {
							// _this.props.actions.clearTokenList()
							_this.props.actions.setSelectedToken(0);
							_this.props.actions.setTokenList(response)

							var enquires = _this.props.tokenList.tokenEnquiresList;

							delete enquires[id]
							_this.props.actions.setTokenEnquiresList(enquires);
							_this.props.actions.clearTokenEnquire();

							if (Object.keys(enquires).length > 0) {
								_this.props.actions.setTokenEnquire(enquires[_this.props.tokenList.list[0].uri]);
							}

							console.log(enquires)
							_this.props.actions.setUserPukCode("")

							this.props.actions.setActionResultData({status: "success", action: "deleteToken", message: i18next.t("success")})
	                    	this.props.actions.setShowActionResult(true)
						});
					} else {
						var tempData = this.props.userReducer.usersPins;

						delete tempData[id]; 
						this.props.actions.setUsersPins(tempData);
						this.props.actions.setUserPukCode("")

						this.props.actions.setErrorResponse(response.json)
						this.setState({showPopup: true})
					}

					console.log(response);
					
				});
			} else {
				this.props.actions.setShowTokenPopup(true);
				this.setState({activeHandler: this.handleDeleteToken.bind(this)})
			}
		}
	}

	handleDeleteToken2f (id) {
		this.props.actions.setTempId(id);
		var _this = this;

		this.props.actions.setNeed2fConfirm(false);

		if (id === true) {
			this.setState({isActiveView: false})
			return;
		}

		var jsonObject = {
			puk: _this.props.userReducer.userPuk
		};

		var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

		var data = {
			"ciHsmTokenUri": id,
			"encryptedData": cipherResult.encryptedData,
			"secretKey": cipherResult.secretKey,
			"twoFactorCode": this.props.login.twoFactorCode
		}

		this.props.actions.removeToken(this.props.base.serviceUrl, this.props.login.accessToken, data)
		.then((response) => {
			if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
				_this.props.actions.getTokenList(_this.props.base.serviceUrl, _this.props.login.accessToken)
				.then((response) => {
					_this.props.actions.setSelectedToken(0);
					_this.props.actions.setTokenList(response)

					var enquires = _this.props.tokenList.tokenEnquiresList;

					delete enquires[id]
					_this.props.actions.setTokenEnquiresList(enquires);
					_this.props.actions.clearTokenEnquire();
					
					if (Object.keys(enquires).length > 0) {
						_this.props.actions.setTokenEnquire(enquires[_this.props.tokenList.list[0].uri]);
					}

					console.log(enquires)

					_this.setState({numberOfPinInput: 0});
					_this.setState({isActiveView: false});

					_this.props.actions.setActionResultData({status: "success", action: "deleteToken", message: i18next.t("success")})
                	_this.props.actions.setShowActionResult(true)
                	_this.props.actions.setUserPukCode("")
				});
			} else {
				if (response.json.code === "AUTHCOMMON-14") {
            		var currentCount = _this.state.numberOfPinInput

            		if (currentCount === 0) {
            			currentCount = _this.props.base.numberOfPinInput - 1;
            		} else {
            			currentCount = currentCount - 1;
            		}

            		_this.setState({numberOfPinInput: currentCount});
					_this.setState({needToReEnterPin: true});
            	}

				this.props.actions.setErrorResponse(response.json)
				this.setState({showPopup: true})
			}

			console.log(response);
			
		});
	}

	handleDeleteConfidantsToken (id) {
  		var exit = false, _this = this, currentTime = new Date().getTime() / 1000;

		this.props.actions.setTempId(id);

		if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		};

		this.props.tokenList.list.forEach(function (token, index) {
			if (token.uri === id && token.status === "BLOCKED") {
				exit = true;
			}
		})

		function checkProperties(obj) {
		    for (var key in obj) {
		        if (obj[key] !== null && Object.keys(obj[key]).length !== 0 && obj[key].constructor === Object)
		            return true;
		    }

		    // if (_this.props.userReducer.usersPins[id] === undefined) {
		    // 	return true;
		    // }

		    return false;
		}

		console.log(checkProperties(this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached))
		exit = checkProperties(this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached)
		
		if (this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached !== null) {
			if (this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.keyIdentifiers.length > 0 || this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.pendingKeyIdentifiers.length > 0) {
				if (this.props.tokenList.list[this.props.tokenList.selectedToken].owner.id === this.props.login.userData.userName) {
					exit = true;
				} else {
					exit = false;
				}
			}
		} else {
			exit = false;
		}

		if (exit) {
			return;
		}

		if (Object.keys(this.props.userReducer.usersPins).length === 0 && this.props.userReducer.usersPins.constructor === Object) {
			this.setState({activeHandler: this.handleDeleteConfidantsToken.bind(this)})
			this.props.actions.setShowTokenPopup(true);
		} else {
			if (this.props.userReducer.usersPins[id] !== undefined && this.props.userReducer.usersPins[id].length > 0) {
				this.props.actions.setShowTokenPopup(false);
				this.setState({isActiveView: true})

				var jsonObject = {
					pin: this.props.userReducer.usersPins[id]
				};

				var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

				var data = {
					"ciHsmTokenUri": id,
					"encryptedData": cipherResult.encryptedData,
					"secretKey": cipherResult.secretKey
				}

				if (this.props.login.is2fEnabled) {
					this.props.actions.removeToken2f(this.props.base.serviceUrl, this.props.login.accessToken, data)
					.then((response) => {
						if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
							this.setState({confirm2fTitle: "deleteToken2fConfirm"})
							this.setState({active2fBodyHandler: this.handleDeleteConfidantsToken2f.bind(this)})
							this.setState({id: id});
							this.props.actions.setNeed2fConfirm(true);
						} else {
							this.props.actions.setErrorResponse(response.json)
							this.setState({showPopup: true})
							this.setState({isActiveView: false})
						}

						return;
					})
					
					return;
				}

				this.props.actions.removeToken(this.props.base.serviceUrl, this.props.login.accessToken, data)
				.then((response) => {
					if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
						_this.props.actions.getTokenList(_this.props.base.serviceUrl, _this.props.login.accessToken)
						.then((response) => {
							// _this.props.actions.clearTokenList()
							_this.props.actions.setSelectedToken(0);
							_this.props.actions.setTokenList(response)

							var enquires = _this.props.tokenList.tokenEnquiresList;

							delete enquires[id]
							_this.props.actions.setTokenEnquiresList(enquires);
							_this.props.actions.clearTokenEnquire();

							if (Object.keys(enquires).length > 0) {
								_this.props.actions.setTokenEnquire(enquires[_this.props.tokenList.list[0].uri]);
							}

							console.log(enquires)

							this.props.actions.setActionResultData({status: "success", action: "deleteToken", message: i18next.t("success")})
	                    	this.props.actions.setShowActionResult(true)
						});
					} else {
						var tempData = this.props.userReducer.usersPins;

						delete tempData[id]; 
						this.props.actions.setUsersPins(tempData);

						this.props.actions.setErrorResponse(response.json)
						this.setState({showPopup: true})
					}

					console.log(response);
					
				});
			} else {
				this.props.actions.setShowTokenPopup(true);
				this.setState({activeHandler: this.handleDeleteToken.bind(this)})
			}
		}
	}

	handleDeleteConfidantsToken2f (id) {
		this.props.actions.setTempId(id);
		var _this = this;

		this.props.actions.setNeed2fConfirm(false);

		if (id === true) {
			this.setState({isActiveView: false})
			return;
		}

		var jsonObject = {
			pin: this.props.userReducer.usersPins[id]
		};

		var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

		var data = {
			"ciHsmTokenUri": id,
			"encryptedData": cipherResult.encryptedData,
			"secretKey": cipherResult.secretKey,
			"twoFactorCode": this.props.login.twoFactorCode
		}

		this.props.actions.removeToken(this.props.base.serviceUrl, this.props.login.accessToken, data)
		.then((response) => {
			if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
				_this.props.actions.getTokenList(_this.props.base.serviceUrl, _this.props.login.accessToken)
				.then((response) => {
					_this.props.actions.setSelectedToken(0);
					_this.props.actions.setTokenList(response)

					var enquires = _this.props.tokenList.tokenEnquiresList;

					delete enquires[id]
					_this.props.actions.setTokenEnquiresList(enquires);
					_this.props.actions.clearTokenEnquire();
					
					if (Object.keys(enquires).length > 0) {
						_this.props.actions.setTokenEnquire(enquires[_this.props.tokenList.list[0].uri]);
					}

					console.log(enquires)

					_this.setState({numberOfPinInput: 0});
					_this.setState({isActiveView: false});

					_this.props.actions.setActionResultData({status: "success", action: "deleteToken", message: i18next.t("success")})
                	_this.props.actions.setShowActionResult(true)
				});
			} else {
				if (response.json.code === "AUTHCOMMON-14") {
            		var currentCount = _this.state.numberOfPinInput

            		if (currentCount === 0) {
            			currentCount = _this.props.base.numberOfPinInput - 1;
            		} else {
            			currentCount = currentCount - 1;
            		}

            		_this.setState({numberOfPinInput: currentCount});
					_this.setState({needToReEnterPin: true});
            	}

				this.props.actions.setErrorResponse(response.json)
				this.setState({showPopup: true})
			}

			console.log(response);
			
		});
	}

	showResetTokenPopup () {
		this.props.actions.setShowResetPopup(true);
	}

	showTrusteesPopup () {
		this.props.actions.setShowTrusteesPopup(true);
	}

	handleResetToken (id) {
		var _this = this, currentTime = new Date().getTime() / 1000;

		this.props.actions.setTempId(id);
		this.props.actions.setShowResetPopup(false);

		if (currentTime > this.props.base.expireTime) {
			this.props.actions.setShowSessionEndHandler(true);
			return;
		};

		this.props.actions.setShowTokenPopup(false);
		this.setState({isActiveView: true})

		var jsonObject = {
			puk : this.props.userReducer.pukForReset,
			newPin : this.props.userReducer.newPinForReset,
			newPuk : this.props.userReducer.newPukForReset
		};

		var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

		var data = {
			"ciHsmTokenUri": id,
			"encryptedData": cipherResult.encryptedData,
			"secretKey": cipherResult.secretKey
		}

		if (this.props.login.is2fEnabled) {
			this.props.actions.resetToken2f(this.props.base.serviceUrl, this.props.login.accessToken, data)
			.then((response) => {
				if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
					this.setState({confirm2fTitle: "resetToken2fConfirm"})
					this.setState({active2fBodyHandler: this.handleResetToken2f.bind(this)})
					this.setState({id: id});
					this.props.actions.setNeed2fConfirm(true);
				} else {
					this.props.actions.setErrorResponse(response.json)
					this.setState({showPopup: true})
					this.setState({isActiveView: false})
				}

				return;
			})
			
			return;
		}

		this.props.actions.resetToken(this.props.base.serviceUrl, this.props.login.accessToken, data)
		.then((response) => {
			if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
				var jsonObject = {
					pin: this.props.userReducer.newPinForReset
				};

				var selectedIndex = this.props.tokenList.selectedToken;

				var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

				var data = {
					"ciHsmTokenUri": id,
					"encryptedData": cipherResult.encryptedData,
					"secretKey": cipherResult.secretKey
				}

				this.props.actions.getTokenEnquire(this.props.base.serviceUrl, this.props.login.accessToken, data)
				.then((response) => {
					if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
						this.props.actions.clearTokenEnquire();

						var pins = this.props.userReducer.usersPins;

					    pins[id] = this.props.userReducer.newPinForReset

					    this.props.actions.setUsersPins(pins);

						var enquires = this.props.tokenList.tokenEnquiresList;
						var list = this.props.tokenList.list

						if (this.props.tokenList.tokenEnquiresList[id] === undefined) {
							enquires[id] = response;
							this.props.actions.setTokenEnquiresList(enquires);

							this.props.tokenList.list.forEach(function (token, index) {
								if (token.uri === id) {
									list[index] = response;
								}

								_this.props.actions.setTokenList(list)
							})

						} else {
							enquires[id] = response;
							this.props.actions.setTokenEnquiresList(enquires);

							this.props.tokenList.list.forEach(function (token, index) {
								if (token.uri === id) {
									list[index] = response;
								}

								_this.props.actions.setTokenList(list)
							})
						}

						this.props.actions.setTokenEnquire(response);
						this.props.actions.setSelectedToken(selectedIndex);

						this.props.actions.setActionResultData({status: "success", action: "resetTokenLabel", message: i18next.t("success")});
                		this.props.actions.setShowActionResult(true);

					} else {
						var tempData = this.props.userReducer.usersPins;

						delete tempData[id]; 
						this.props.actions.setUsersPins(tempData);

						this.props.actions.setErrorResponse(response.json)
						this.setState({showPopup: true})
					}
				});
			} else {
				// var tempData = this.props.userReducer.usersPins;

				// delete tempData[id]; 
				// this.props.actions.setUsersPins(tempData);

				this.props.actions.setErrorResponse(response.json)
				this.setState({showPopup: true})
			}

			console.log(response);
			
		});

	}

	handleResetToken2f (id) {
		// this.props.actions.setTempId(id);
		var _this = this;

		this.props.actions.setNeed2fConfirm(false);

		if (id === true) {
			this.setState({isActiveView: false})
			return;
		}

		var jsonObject = {
			puk : this.props.userReducer.pukForReset,
			newPin : this.props.userReducer.newPinForReset,
			newPuk : this.props.userReducer.newPukForReset
		};

		var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

		var data = {
			"ciHsmTokenUri": id,
			"encryptedData": cipherResult.encryptedData,
			"secretKey": cipherResult.secretKey,
			"twoFactorCode": this.props.login.twoFactorCode
		}

		this.props.actions.resetToken(this.props.base.serviceUrl, this.props.login.accessToken, data)
		.then((response) => {
			if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
				var jsonObject = {
					pin: this.props.userReducer.newPinForReset
				};

				var selectedIndex = this.props.tokenList.selectedToken;

				var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

				var data = {
					"ciHsmTokenUri": id,
					"encryptedData": cipherResult.encryptedData,
					"secretKey": cipherResult.secretKey
				}

				this.props.actions.getTokenEnquire(this.props.base.serviceUrl, this.props.login.accessToken, data)
				.then((response) => {
					if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
						this.props.actions.clearTokenEnquire();

						var pins = this.props.userReducer.usersPins;

					    pins[id] = this.props.userReducer.newPinForReset

					    this.props.actions.setUsersPins(pins);

						var enquires = this.props.tokenList.tokenEnquiresList;
						var list = this.props.tokenList.list

						if (this.props.tokenList.tokenEnquiresList[id] === undefined) {
							enquires[id] = response;
							this.props.actions.setTokenEnquiresList(enquires);

							this.props.tokenList.list.forEach(function (token, index) {
								if (token.uri === id) {
									list[index] = response;
								}

								_this.props.actions.setTokenList(list)
							})

						} else {
							enquires[id] = response;
							this.props.actions.setTokenEnquiresList(enquires);

							this.props.tokenList.list.forEach(function (token, index) {
								if (token.uri === id) {
									list[index] = response;
								}

								_this.props.actions.setTokenList(list)
							})
						}

						this.props.actions.setTokenEnquire(response);
						this.props.actions.setSelectedToken(selectedIndex);

						this.props.actions.setActionResultData({status: "success", action: "resetTokenLabel", message: i18next.t("success")});
                		this.props.actions.setShowActionResult(true);

					} else {

						this.props.actions.setErrorResponse(response.json)
						this.setState({showPopup: true})
					}
				});
			} else {
				if (response.json.code === "AUTHCOMMON-14") {
            		var currentCount = _this.state.numberOfPinInput

            		if (currentCount === 0) {
            			currentCount = _this.props.base.numberOfPinInput - 1;
            		} else {
            			currentCount = currentCount - 1;
            		}

            		_this.setState({numberOfPinInput: currentCount});
					_this.setState({needToReEnterPin: true});
            	}

				this.props.actions.setErrorResponse(response.json)
				this.setState({showPopup: true})
			}

			console.log(response);
			
		});
	}

	checkTokenOwner (owner) {
		var returnValue = false;

		if (owner.id === this.props.login.login) {
			returnValue = true;
		}
		// var found = false;
		// for (var i = 0; i < confidants.length; i += 1) {
		//     if (confidants[i].id === owner.id) {
		//         found = true;
		//         break;
		//     }
		// }

		return returnValue;
	}

	componentDidMount() {

	}

	copyToClipboardUri (text, element) {
		var textArea, copy;

	    function isOS() {
	        return navigator.userAgent.match(/ipad|iphone/i);
	    }

	    function createTextArea(text) {
	        textArea = document.createElement('textArea');
	        textArea.value = text;
	        textArea.style.cssText = "position: absolute";
	        textArea.style.cssText = "top: -9999px";
	        document.body.appendChild(textArea);
	    }

	    function selectText() {
	        var range,
	            selection;

	        if (isOS()) {
	            range = document.createRange();
	            range.selectNodeContents(textArea);
	            selection = window.getSelection();
	            selection.removeAllRanges();
	            selection.addRange(range);
	            textArea.setSelectionRange(0, 999999);
	        } else {
	            textArea.select();
	        }
	    }

	    function copyToClipboard() {        
	        document.execCommand('copy');
	        document.body.removeChild(textArea);
	    }

	    copy = function(text) {
	        createTextArea(text);
	        selectText();
	        copyToClipboard();
	        document.getElementById(element).style.color = "green";
	        window.setTimeout(function () {
	        	document.getElementById(element).style.color = "#414549";
	        }, 1000)
		};

		copy(text);
	}

	render() {
		let removeTokenEnabled = false;
		let showTokenOpen = 'dropdown-menu dropdown-menu-right'
		if (this.state.showTokenActions) {
			showTokenOpen = 'dropdown-menu dropdown-menu-right show'
		}

		if (this.props.base.needRefresh !== false) {
			this.handleRefreshToken(this.props.base.needRefresh)
		}

		if (this.props.base.showError !== false) {
			this.setState({showPopup: true})
			this.props.actions.setShowError(false)
		}

		if (this.props.tokenList.selectedToken !== null && this.props.tokenList.list[this.props.tokenList.selectedToken] !== undefined && this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri] !== undefined) {

			function checkProperties(obj) {
			    for (var key in obj) {
			        if (obj[key] !== null && Object.keys(obj[key]).length !== 0 && obj[key].constructor === Object)
			            return false;
			    }
			    return true;
			}

			removeTokenEnabled = checkProperties(this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached)
			
			if ((this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached !== null && this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.keyIdentifiers !== null && this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.keyIdentifiers.length > 0) || (this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached !== null && this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.pendingKeyIdentifiers.length > 0)) {
				removeTokenEnabled = false;
			}
			
		}

		return (
			<div className="body-container" style={{paddingLeft: "80px", paddingRight: "10px"}}>
				{
					this.props.tokenList.list[this.props.tokenList.selectedToken]
					? <div>
						<div style={{paddingLeft: "0px", paddingRight: "0px", marginTop: "10px"}} className={this.props.tokenList.list[this.props.tokenList.selectedToken].status === "BLOCKED" ? "table-border-blocked row col-lg-12 col-md-12 col-sm-12 col-xs-12" : "table-border row col-lg-12 col-md-12 col-sm-12 col-xs-12"}>
							<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-3 col-md-3 col-sm-12 col-xs-3">
								<div className="table-header">
									<label>{i18next.t("tokenName")}</label>
								</div>
								<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
									<label className="col-lg-12 col-md-12 col-sm-12 col-xs-12">{this.props.tokenList.list[this.props.tokenList.selectedToken].title}</label>
									<label className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
										{
											this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri]
											? <i style={{fontSize: "2em", cursor: "pointer"}} className="fa fa-unlock" onClick={this.handleRemovePin.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].uri)}></i>
											: <i style={{fontSize: "2em", cursor: "pointer"}} className="fa fa-lock" onClick={this.handleRefreshToken.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].uri)}></i>
										}
									</label>
								</div>
							</div>
							<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-3 col-md-3 col-sm-12 col-xs-3">
								<div className="table-header">
									<label>{i18next.t("tokenUri")}</label>
								</div>
								<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
									<label>
										{
											this.props.tokenList.list[this.props.tokenList.selectedToken].uri
										}
									</label>
									<label className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
										<i className="fa fa-copy" style={{fontSize: "1.7em", cursor: "pointer"}} id={"idName" + this.props.tokenList.list[this.props.tokenList.selectedToken].uri} onClick={this.copyToClipboardUri.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].uri, "idName" + this.props.tokenList.list[this.props.tokenList.selectedToken].uri)}></i>
									</label>
								</div>
							</div>
							<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-2 col-md-2 col-sm-12 col-xs-3">
								<div className="table-header">
									<label>{i18next.t("tokenStatus")}</label>
								</div>
								<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
									<label>
											{i18next.t(this.props.tokenList.list[this.props.tokenList.selectedToken].status)}
									</label>
								</div>
							</div>
							<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-3 col-md-3 col-sm-12 col-xs-3">
								<div className="table-header">
									<label>{i18next.t("tokenChangedDate")}</label>
								</div>
								<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
									{
										<label>
											{
												this.props.actions.formatDate(this.props.tokenList.list[this.props.tokenList.selectedToken].statusChangeDateTime)
											}
										</label>
									}
								</div>
							</div>
							<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-1 col-md-1 col-sm-12 col-xs-12">
								<div className="table-header min-heigth-33">
									<label></label>
								</div>
								<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
									<div className="dropdown" style={{marginTop: "5px"}}>
			                            <button type="button" onClick={this.handleShowTokenActions} className="btn btn-cog dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showTokenActions}>
											<span><i style={{color: "#1C92C7", fontSize: "2.5em"}} className="fa fa-cog"></i></span>
										</button>
			                            <div className={showTokenOpen}>
			                            	{
			                            		this.props.tokenList.list[this.props.tokenList.selectedToken].status !== "RESET_BY_SO"
			                            		? <span className="dropdown-item pointer" onClick={this.handleRefreshToken.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].uri)}>{i18next.t("refreshTokenData")}</span>
			                            		: null
			                            	}
			                                
			                                
			                                {/*
			                                	<span className="dropdown-item pointer">{i18next.t("generateKeys")}</span>
			                                	<span className="dropdown-item pointer" onClick={this.showImportCertificatePopup}>{i18next.t("importCertificate")}</span>
			                                	
			                                */}
			                                {
			                                	this.props.tokenList.list[this.props.tokenList.selectedToken].owner.id === this.props.login.userData.userName && this.props.tokenList.list[this.props.tokenList.selectedToken].status !== "RESET_BY_SO"
			                                	? <span className={this.props.tokenList.list[this.props.tokenList.selectedToken].status === "BLOCKED" ? "dropdown-item-body-disabled" : "dropdown-item pointer"} onClick={this.showImportCertificatePopup.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].uri)}>{i18next.t("importCertificate")}</span>
			                                	: null
			                                }
			                                {
			                                	this.props.tokenList.list[this.props.tokenList.selectedToken].owner.id === this.props.login.userData.userName && this.props.tokenList.list[this.props.tokenList.selectedToken].status !== "RESET_BY_SO"
			                                	? this.props.tokenList.list[this.props.tokenList.selectedToken].status === "ACTIVE"
			                                		? <span className="dropdown-item pointer" onClick={this.handleBlockToken.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].uri)}>{i18next.t("blockToken")}</span>
			                                		: <span className="dropdown-item pointer" onClick={this.handleUnblockToken.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].uri)}>{i18next.t("unblockToken")}</span>
			                                	: null
			                                }
			                                {
			                                	this.props.tokenList.list[this.props.tokenList.selectedToken].owner.id === this.props.login.userData.userName && this.props.tokenList.list[this.props.tokenList.selectedToken].status !== "RESET_BY_SO"
			                                	? <span className="dropdown-item pointer" onClick={this.copyToClipboard.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].uri)}>{i18next.t("copyUriToClipboard")}</span>
			                                	: null
			                                }
			                                
			                                {
			                                	this.props.tokenList.list[this.props.tokenList.selectedToken].owner.id === this.props.login.userData.userName && this.props.tokenList.list[this.props.tokenList.selectedToken].status !== "RESET_BY_SO"
			                                	? <span className={this.props.tokenList.list[this.props.tokenList.selectedToken].status === "BLOCKED" || (this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached !== null && (this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.AGREEMENT !== undefined && this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.SIGNATURE !== undefined)) ? "dropdown-item-body-disabled" : "dropdown-item pointer"} onClick={this.showGenerateKeyPopup.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].uri)}>{i18next.t("generateKeys")}</span>
			                                	: null
			                                }
			                                {
			                                	this.props.tokenList.list[this.props.tokenList.selectedToken].owner.id === this.props.login.userData.userName && this.props.tokenList.list[this.props.tokenList.selectedToken].status !== "RESET_BY_SO"
			                                	? <span className={this.props.tokenList.list[this.props.tokenList.selectedToken].status === "BLOCKED" ? "dropdown-item-body-disabled" : "dropdown-item pointer"} onClick={this.showChangePinPopup.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].uri)}>{i18next.t("confirmChangePinLabel")}</span>
			                                	: null
			                                }
			                                {
			                                	this.props.tokenList.list[this.props.tokenList.selectedToken].owner.id === this.props.login.userData.userName && this.props.tokenList.list[this.props.tokenList.selectedToken].status !== "RESET_BY_SO"
			                                	? <span className={this.props.tokenList.list[this.props.tokenList.selectedToken].status === "BLOCKED" ? "dropdown-item-body-disabled" : "dropdown-item pointer"} onClick={this.showChangePinByPukPopup.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].uri)}>{i18next.t("restorePin")}</span>
			                                	: null
			                                }
			                                {
			                                	this.props.tokenList.list[this.props.tokenList.selectedToken].owner.id !== this.props.login.userData.userName && this.props.tokenList.list[this.props.tokenList.selectedToken].status !== "RESET_BY_SO"
			                                	? <span className={this.props.tokenList.list[this.props.tokenList.selectedToken].status === "BLOCKED" ? "dropdown-item-body-disabled" : "dropdown-item pointer"} onClick={this.handleDeleteConfidantsToken.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].uri)}>{i18next.t("deleteConfidantsToken")}</span>
			                            		: null
			                                }
			                                {
			                                	this.props.tokenList.list[this.props.tokenList.selectedToken].owner.id === this.props.login.userData.userName && this.props.tokenList.list[this.props.tokenList.selectedToken].status !== "RESET_BY_SO"
			                                	? <span className={this.props.tokenList.list[this.props.tokenList.selectedToken].status === "BLOCKED" ? "dropdown-item-body-disabled" :  removeTokenEnabled === true ? "dropdown-item pointer" : "dropdown-item-body-disabled"} onClick={this.deleteToken.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].uri)}>{i18next.t("deleteToken")}</span>
			                            		: null
			                                }
			                                {
			                                	this.props.tokenList.list[this.props.tokenList.selectedToken].owner.id === this.props.login.userData.userName
			                                	? <span className={this.props.tokenList.list[this.props.tokenList.selectedToken].status === "BLOCKED" ? "dropdown-item-body-disabled" : "dropdown-item pointer"} onClick={this.showResetTokenPopup}>{i18next.t("resetToken")}</span>
			                            		: null
			                                }

			                                {
			                                	this.props.tokenList.list[this.props.tokenList.selectedToken].confidants !== undefined && this.props.tokenList.list[this.props.tokenList.selectedToken].confidants !== null && this.props.tokenList.list[this.props.tokenList.selectedToken].confidants.length > 0 && this.checkTokenOwner(this.props.tokenList.list[this.props.tokenList.selectedToken].owner)
			                                	? <span className={this.props.tokenList.list[this.props.tokenList.selectedToken].status === "BLOCKED" ? "dropdown-item-body-disabled" : "dropdown-item pointer"} onClick={this.showTrusteesPopup}>{i18next.t("trustees")}</span>
			                                	: null
			                                }
			                            </div>
			                        </div>
								</div>
							</div>
						</div>
						<div style={{textAlign: "center"}}>
							{
								this.props.userReducer.usersPins[this.props.tokenList.list[this.props.tokenList.selectedToken].uri]
								? null
								: <div>
									<label>
										{i18next.t("needPinFirstPart")}
										<i style={{color: "#1C92C7", fontSize: "1.1em"}} className="fa fa-cog"></i>
										{i18next.t("needPinSecondPart")}
									</label>
								</div>
							}
						</div>
					</div>
					: null
				}
				{
                	this.props.base.showUserInfo
                	? <UserInfoPopup />
                	: null
                }
                <div>
					{
						this.props.userReducer.showTokenPopup
						? <TokenPinPopup closeTokenPopup={this.state.activeHandler} />
			            : null
			        }
		        </div>
		        {
		        	this.props.userReducer.showImportCertificatePopup
					? <ImportCertificatePopup closeCertificatePopup={this.importCertificate.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].uri)} />
					: null
		        }
		        {
		        	this.props.userReducer.showGenerateKeyPopup
					? <GenerateKeyPopup closeGenerateKeyPopup={this.generateKey.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].uri)} />
					: null
		        }
		        {
		        	this.props.userReducer.showChangePinPopup
					? <ChangePinPopup closeChangePinPopup={this.changePin.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].uri)} />
					: null
		        }
		        {
		        	this.props.userReducer.showChangePinByPukPopup
					? <ChangePinByPukPopup closeChangePinByPukPopup={this.changePinByPuk.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].uri)} />
					: null
		        }

		        {
					this.props.userReducer.showTokenPukPopup
					? <TokenPukPopup closeTokenPukPopup={this.handleDeleteToken.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].uri)} />
		            : null
		        }
		        {
					this.props.userReducer.showResetPopup
					? <ResetPopup closeResetPopup={this.handleResetToken.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].uri)} />
		            : null
		        }
                <ActiveKeysView />
                <WaitingKeysView />
                <OtherKeysView />
                <OtherCertificatesView />
                <OtherCertificatesRequestsView />
                {
                    this.props.base.showActionResult
                    ? <ActionResultInfoPopup resultData={this.state} />
                    : null
                }
				{
					this.state.showPopup
					? <ErrorMessagePopup closeMessagePopup={this.showMessagePopup.bind(this)} data={this.props.userReducer.errorResponse} />
					: null
				}
				{
					this.props.userReducer.showTwoFactorSettings
					? <TwoFactorContainerPopup closeTwoFactorContainerPopup={this.closeTwoFactorContainer.bind(this)} />
					: null
				}
				{
					this.props.base.globalProcessing
					? <div className='popup-dark'>
						<div className={this.props.profile.certificateAutoIssuing ? 'loading-p-inner' : 'loading-p-inner-one'}>
							{
								this.props.profile.certificateAutoIssuing
								? <div>
									<p style={{fontSize: "18px"}}>{i18next.t("keyGenenerationTitle")}&nbsp;{i18next.t(this.props.profile.keyGenerationProcess)}</p>
									<p style={{fontSize: "18px"}}>{i18next.t("certIssuingTitle")}&nbsp;{i18next.t(this.props.profile.certificateIssuingProcess)}</p>
									<p style={{fontSize: "18px"}}>{i18next.t("certImportTitle")}&nbsp;{i18next.t(this.props.profile.certificateImportProcess)}</p>
								  </div>
								: <div>
									<p style={{fontSize: "18px"}}>{i18next.t("keyGenenerationTitle")}&nbsp;{i18next.t(this.props.profile.keyGenerationProcess)}</p>
								  </div>
							}
						</div>
						<div className="loading-div-inner">
							<i className="fa fa-spinner fa-pulse"></i>
						</div>
					  </div>
					: null
				}
				{
					this.props.userReducer.need2fConfirm && this.state.isActiveView === true
					? <TwoFactorConfirmPopup initAction={this.state.active2fBodyHandler} title={this.state.confirm2fTitle} id={this.state.id} tries={this.state.numberOfPinInput} />
					: null
				}
				{
					this.props.base.showSessionEndHandler
					? <SessionEndPopup />
		            : null
		        }
		        {
		        	this.props.base.showTrusteesPopup
		        	? <TrusteesPopup />
		        	: null
		        }
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        tokenList: state.tokenListReducer,
        userReducer: state.userReducer,
        keyGen: state.keyGenReducer,
        profile: state.keyGenProfilesReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	formatDate, cipher,
    	blockToken, unblockToken,
    	getTokenEnquire, setTokenEnquire,
    	clearSelectedToken, setSelectedToken,
    	getTokenList, setTokenList, clearTokenEnquire,
    	setShowTokenPopup, setTempId, setUsersPins,
    	setShowImportCertificatePopup, importCertificate,
    	setShowActionResult, setActionResultData,
    	setErrorResponse, clearCertificateData, clearCertificateFileName,
    	setTokenEnquiresList, setShowGenerateKeyPopup,
    	generateKeys, certificateIssue, setGlobalProcessing,
    	setKeyGenerationProcess, setCertificateIssuingProcess,
		setCertificateImportProcess, setNeedRefresh,
		setShowTwoFactorSettings, setNeed2fConfirm,
		generateKeys2f, certificateIssue2f,
		setIsElectronicStamp, setCertificateAutoIssuing,
		blockToken2f, unblockToken2f, setActiveView,
		setShowError, removeToken, removeToken2f,
		setShowChangePinPopup, changePinByPin,
		changePinByPin2f, setOldPin, setNewPin,
		setShowSessionEndHandler, clearTokenList,
		setPuk, setShowChangePinByPukPopup,
		changePinByPuk, changePinByPuk2f,
		setGeneratedKeysType, setShowTokenPukPopup, setUserPukCode,
		resetToken, resetToken2f, setShowResetPopup,
		getPublicKeyCurrentUtcDateTime, setShowTrusteesPopup

    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Body);