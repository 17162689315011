export const setIsIEBrowser = (value) => (dispatch) => {
    dispatch({
        type: 'SET_IS_IE_BROWSER',
        payload: value
    })
}

export const setExpireInTime = (value) => (dispatch) => {
    dispatch({
        type: 'SET_EXPIRE_IN_TIME',
        payload: value
    })
}

export const setTokenExpiredAfterSec = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_EXPIRED_AFTER_SEC',
        payload: value
    })
}

export const setDefaults = (value) => (dispatch) => {
    dispatch({
        type: 'SET_DEFAULTS',
        payload: value
    })
}

export const setLoginInput = (value) => (dispatch) => {
    dispatch({
        type: 'SET_LOGIN_INPUT',
        payload: value
    })
}

export const setPasswordInput = (value) => (dispatch) => {
    dispatch({
        type: 'SET_PASSWORD_INPUT',
        payload: value
    })
}

export const setIsLoggedIn = (value) => (dispatch) => {
    dispatch({
        type: 'SET_IS_LOGGED_IN',
        payload: value
    })
}

export const setToken = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN',
        payload: value
    })
}

export const clearToken = (value) => (dispatch) => {
    dispatch({
        type: 'CLEAR_TOKEN',
        payload: value
    })
}

export const setLoggedInUserData = (value) => (dispatch) => {
    dispatch({
        type: 'SET_LOGGED_IN_USER_DATA',
        payload: value
    })
}

export const clearLoggedInUserData = (value) => (dispatch) => {
    dispatch({
        type: 'CLEAR_LOGGED_IN_USER_DATA',
        payload: value
    })
}

export const setAllConfigFields = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ALL_CONFIG_FIELDS',
        payload: value
    })
}

export const setVersion = (data) => (dispatch) => {
    dispatch({
        type: 'SET_VERSION',
        payload: data
    })
}

export const setAllUserFields = (user) => (dispatch) => {
    dispatch({
        type: 'SET_ALL_USER_FIELDS',
        payload: user
    })
}

export const setSelectedUser = (user) => (dispatch) => {
    dispatch({
        type: 'SET_SELECTED_USER',
        payload: user
    })
}

export const setShowUserInfo = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_USER_INFO',
        payload: value
    })
}

export const setTokenList = (list) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_LIST',
        payload: list
    })
}

export const clearTokenList = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_TOKEN_LIST',
        payload: null
    })
}

export const changeDrawerState = (state) => (dispatch) => {
    dispatch({
        type: 'CHANGE_DRAWER_STATE',
        payload: state
    })
}

export const setSelectedToken = (token) => (dispatch) => {
    dispatch({
        type: 'SET_SELECTED_TOKEN',
        payload: token
    })
}

export const clearSelectedToken = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_SELECTED_TOKEN',
        payload: null
    })
}

export const setGlobalProcessing = (value) => (dispatch) => {
    dispatch({
        type: 'SET_GLOBAL_PROCESSING',
        payload: value
    })
}

export const setPublicKeyCurrentUtcDateTime = (data) => (dispatch) => {
    dispatch({
        type: 'SET_PUBLIC_KEY_CURRENT_UTC_DATE_TIME',
        payload: data
    })
}

export const clearPublicKeyCurrentUtcDateTime = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_PUBLIC_KEY_CURRENT_UTC_DATE_TIME',
        payload: null
    })
}

export const setTokenEnquire = (data) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_ENQUIRE',
        payload: data
    })
}

export const clearTokenEnquire = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_TOKEN_ENQUIRE',
        payload: null
    })
}

export const setUserPin = (pin) => (dispatch) => {
    dispatch({
        type: 'SET_USER_PIN',
        payload: pin
    })
}

export const setUri = (uri) => (dispatch) => {
    dispatch({
        type: 'SET_URI',
        payload: uri
    })
}

export const clearUserPin = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_USER_PIN',
        payload: null
    })
}

export const clearUri = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_URI',
        payload: null
    })
}

export const setUserPuk = (puk) => (dispatch) => {
    dispatch({
        type: 'SET_USER_PUK',
        payload: puk
    })
}

export const clearUserPuk = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_USER_PUK',
        payload: null
    })
}

export const setShowPopup = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_POPUP',
        payload: value
    })
}

export const setShowImportPopup = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_IMPORT_POPUP',
        payload: value
    })
}

export const setUsersPins = (value) => (dispatch) => {
    dispatch({
        type: 'SET_USERS_PINS',
        payload: value
    })
}

export const clearUsersPins = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_USERS_PINS',
        payload: null
    })
}

export const setShowTokenPopup = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_TOKEN_POPUP',
        payload: value
    })
}

export const setShowTokenPukPopup = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_TOKEN_PUK_POPUP',
        payload: value
    })
}

export const setTempId = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TEMP_ID',
        payload: value
    })
}

export const clearTempId = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_TEMP_ID',
        payload: null
    })
}

export const setShowImportCertificatePopup = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_IMPORT_CERTIFICATE_POPUP',
        payload: value
    })
}

export const setShowGenerateKeyPopup = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_GENERATE_KEY_POPUP',
        payload: value
    })
}

export const setShowChangePinPopup = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_CHANGE_PIN_POPUP',
        payload: value
    })
}

export const setCertificateData = (data) => (dispatch) => {
    dispatch({
        type: 'SET_CERTIFICATE_DATA',
        payload: data
    })
}

export const clearCertificateData = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_CERTIFICATE_DATA',
        payload: null
    })
}

export const setCertificateFileName = (name) => (dispatch) => {
    dispatch({
        type: 'SET_CERTIFICATE_FILE_NAME',
        payload: name
    })
}

export const clearCertificateFileName = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_CERTIFICATE_DATA',
        payload: null
    })
}

export const setShowActionResult = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_ACTION_RESULT',
        payload: value
    })
}

export const setActionResultData = (data) => (dispatch) => {
    dispatch({
        type: 'SET_ACTION_RESULT_DATA',
        payload: data
    })
}

export const setActionResultArr = (data) => (dispatch) => {
    dispatch({
        type: 'SET_ACTION_RESULT_ARR',
        payload: data
    })
}

export const setErrorResponse = (data) => (dispatch) => {
    dispatch({
        type: 'SET_ERROR_RESPONSE',
        payload: data
    })
}

export const clearErrorRespons = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_ERROR_RESPONSE',
        payload: null
    })
}

export const setTokenEnquiresList = (list) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_ENQUIRES_LIST',
        payload: list
    })
}

export const clearTokenEnquiresList = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_TOKEN_ENQUIRES_LIST',
        payload: null
    })
}

export const setTwoFactorEnabled = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TWO_FACTOR_ENABLED',
        payload: value
    })
}

export const clearTwoFactorEnabled = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_TWO_FACTOR_ENABLED',
        payload: null
    })
}

export const setTwoFactorCode = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TWO_FACTOR_CODE',
        payload: value
    })
}

export const clearTwoFactorCode = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_TWO_FACTOR_CODE',
        payload: null
    })
}

export const setSelectedProvider = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SELECTED_PROVIDER',
        payload: value
    })
}

export const setAllKeyGenParams = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ALL_KEY_GEN_PARAMS',
        payload: value
    })
}

export const clearAllKeyGenParams = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_ALL_KEY_GEN_PARAMS',
        payload: null
    })
}

export const setAllowedRegistarCipIdList = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ALLOWED_REGISTRAR_CIP_ID',
        payload: value
    })
}

export const clearAllowedRegistarCipIdList = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_ALLOWED_REGISTRAR_CIP_ID',
        payload: null
    })
}

export const setSelectedRegistarCipId = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SELECTED_REGISTRAR_CIP_ID',
        payload: value
    })
}

export const clearSelectedRegistarCipId = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_SELECTED_REGISTRAR_CIP_ID',
        payload: null
    })
}

export const setProfilesList = (list) => (dispatch) => {
    dispatch({
        type: 'SET_PROFILES_LIST',
        payload: list
    })
}

export const clearProfilesList = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_PROFILES_LIST',
        payload: null
    })
}

export const setSelectedProfile = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SELECTED_PROFILE',
        payload: value
    })
}

export const clearSelectedProfile = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_SELECTED_PROFILE',
        payload: null
    })
}

export const setProfileFields = (value) => (dispatch) => {
    dispatch({
        type: 'SET_PROFILE_FIELDS',
        payload: value
    })
}

export const clearProfileFields = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_PROFILE_FIELDS',
        payload: null
    })
}

export const setIsElectronicStamp = (value) => (dispatch) => {
    dispatch({
        type: 'SET_IS_ELECTRONIC_STAMP',
        payload: value
    })
}

export const setGeneratedKeysType = (value) => (dispatch) => {
    dispatch({
        type: 'SET_GENERATED_KEYS_TYPE',
        payload: value
    })
}

export const setCertificateAutoIssuing = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CERTIFICATE_AUTO_ISSUING',
        payload: value
    })
}

export const setKeyGenerationProcess = (value) => (dispatch) => {
    dispatch({
        type: 'SET_KEY_GENERATION_PROCESS',
        payload: value
    })
}

export const setCertificateIssuingProcess = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CERTIFICATE_ISSUING_PROCESS',
        payload: value
    })
}

export const setCertificateImportProcess = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CERTIFICATE_IMPORT_PROCESS',
        payload: value
    })
}

export const setNeedRefresh = (value) => (dispatch) => {
    dispatch({
        type: 'SET_NEED_REFRESH',
        payload: value
    })
}

export const setTwoFactorChannelId = (id) => (dispatch) => {
    dispatch({
        type: 'SET_TWO_FACTOR_CHANNEL_ID',
        payload: id
    })
}

export const setTwoFactorSettings = (settings) => (dispatch) => {
    dispatch({
        type: 'SET_TWO_FACTOR_SETTINGS',
        payload: settings
    })
}

export const setTwoFactorAvailabelChannels = (channels) => (dispatch) => {
    dispatch({
        type: 'SET_TWO_FACTOR_AVAILABEL_CHANNELS',
        payload: channels
    })
}

export const setShowTwoFactorSettings = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_TWO_FACTOR_SETTINGS',
        payload: value
    })
}


export const setUpdate2fSettingsGrant = (value) => (dispatch) => {
    dispatch({
        type: 'SET_UPDATE_2F_SETTINGS_GRANT',
        payload: value
    })
}

export const setIs2fEnabled = (value) => (dispatch) => {
    dispatch({
        type: 'SET_IS_2F_ENABLED',
        payload: value
    })
}

export const setShowLogoutHandler = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_LOGOUT_HANDLER',
        payload: value
    })
}

export const setNeedReLogin = (value) => (dispatch) => {
    dispatch({
        type: 'SET_NEED_RELOGIN',
        payload: value
    })
}

export const setCertificateIssuingGrant = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CERTIFICATE_ISSUING_GRANT',
        payload: value
    })
}

export const setNeed2fConfirm = (value) => (dispatch) => {
    dispatch({
        type: 'SET_NEED_2F_CONFIRM',
        payload: value
    })
}

export const setActiveView = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ACTIVE_VIEW',
        payload: value
    })
}

export const setShowError = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_ERROR',
        payload: value
    })
}

export const setOldPin = (value) => (dispatch) => {
    dispatch({
        type: 'SET_OLD_PIN',
        payload: value
    })
}

export const setNewPin = (value) => (dispatch) => {
    dispatch({
        type: 'SET_NEW_PIN',
        payload: value
    })
}

export const setExpireTime = (value) => (dispatch) => {
    dispatch({
        type: 'SET_EXPIRE_TIME',
        payload: value
    })
}

export const setShowSessionEndHandler = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_SESSION_END_HANDLER',
        payload: value
    })
}

export const setNeedToLogout = (value) => (dispatch) => {
    dispatch({
        type: 'SET_NEED_TO_LOGOUT',
        payload: value
    })
}

export const setTimeDelta = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TIME_DELTA',
        payload: value
    })
}

export const setPuk = (value) => (dispatch) => {
    dispatch({
        type: 'SET_PUK',
        payload: value
    })
}

export const setUserPukCode = (value) => (dispatch) => {
    dispatch({
        type: 'SET_USER_PUK_CODE',
        payload: value
    })
}

export const setShowChangePinByPukPopup = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_CHANGE_PIN_BY_PUK_POPUP',
        payload: value
    })
}

export const setChangePasswordGrant = (value) => (dispatch) => {
    dispatch({
        type: 'CHANGE_PASSWORD_GRANT',
        payload: value
    })
}

export const setKeyTypeSignatureSetted = (value) => (dispatch) => {
    dispatch({
        type: 'SET_KEY_TYPE_SIGNATURE_SETTED',
        payload: value
    })
}

export const setKeyTypeAgreementSetted = (value) => (dispatch) => {
    dispatch({
        type: 'SET_KEY_TYPE_AGREEMENT_SETTED',
        payload: value
    })
}

export const setIsPrivateCert = (value) => (dispatch) => {
    dispatch({
        type: 'SET_IS_PRIVATE_CERT',
        payload: value
    })
}

export const setShowResetPopup = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_RESET_POPUP',
        payload: value
    })
}

export const setPukForReset = (value) => (dispatch) => {
    dispatch({
        type: 'SET_PUK_FOR_RESET',
        payload: value
    })
}

export const setNewPinForReset = (value) => (dispatch) => {
    dispatch({
        type: 'SET_NEW_PIN_FOR_RESET',
        payload: value
    })
}

export const setNewPukForReset = (value) => (dispatch) => {
    dispatch({
        type: 'SET_NEW_PUK_FOR_RESET',
        payload: value
    })
}

export const setWarningsList = (list) => (dispatch) => {
    dispatch({
        type: 'SET_WARNINGS_LIST',
        payload: list
    })
}

export const clearWarningsList = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_WARNINGS_LIST',
        payload: null
    })
}

export const setIsKerberos = (value) => (dispatch) => {
    dispatch({
        type: 'SET_IS_KERBEROS',
        payload: value
    })
}

export const setShowTrusteesPopup = (value) => (dispatch) => {
    dispatch({
        type: 'SHOW_TRUSTEES_POPUP',
        payload: value
    })
}