import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { getRegistrarCips, getCertificateProfiles } from '../actions/api'
import { setShowGenerateKeyPopup, setAllKeyGenParams, clearAllKeyGenParams, setAllowedRegistarCipIdList, clearAllowedRegistarCipIdList, setSelectedRegistarCipId,
          clearSelectedRegistarCipId, setProfilesList, clearProfilesList, setSelectedProfile, clearSelectedProfile, setProfileFields, clearProfileFields,
          setErrorResponse, setShowActionResult, setActionResultData, setIsElectronicStamp, setGeneratedKeysType, setCertificateAutoIssuing, setShowError,
          setKeyTypeSignatureSetted, setKeyTypeAgreementSetted, setIsPrivateCert } from '../actions/localStates'

class GenerateKeyPopup extends Component {

  constructor(props) {
      super(props);
      this.state = {
          isLoading: true,
          requiredFields: []
      }

      this.closePopup = this.closePopup.bind(this);

      this.handleChangeKeyType = this.handleChangeKeyType.bind(this);
      this.handleCheckCertAutoIssuing = this.handleCheckCertAutoIssuing.bind(this);

      this.handleChangeCertificateProfile = this.handleChangeCertificateProfile.bind(this);

      this.handleCheckIsPrivateCert = this.handleCheckIsPrivateCert.bind(this);
  }

  closePopup () {
      this.props.actions.setShowGenerateKeyPopup(false);
  }

  handleCheck () {
    this.props.actions.setIsElectronicStamp(!this.props.profile.isElectronicStamp)
  }

  handleCheckSignature () {
    this.props.actions.setKeyTypeSignatureSetted(!this.props.profile.keyTypeSignatureSetted)
  }

  handleCheckAgreement () {
    this.props.actions.setKeyTypeAgreementSetted(!this.props.profile.keyTypeAgreementSetted)
  }

  handleCheckCertAutoIssuing () {
    this.props.actions.setCertificateAutoIssuing(!this.props.profile.certificateAutoIssuing)
  }

  handleCheckIsPrivateCert () {
    this.props.actions.setIsPrivateCert(!this.props.profile.isPrivateCert)
  }

  handleChangeKeyType (e) {
    this.props.actions.setGeneratedKeysType(e.target.value)
  }

  handleChangeCertificateProfile (e) {
    var _this = this;
    this.setState({requiredFields: []})
    var index = parseInt(e.target.value, 10), requires = [];
    this.props.actions.setSelectedProfile(index);

    this.props.actions.setProfileFields(this.props.profile.profilesList[index].fields)

    var fieldsArray = this.props.profile.profilesList[index].fields
    // eslint-disable-next-line
    Object.keys(fieldsArray).map((key, index) => {
      if (!fieldsArray[key].optional) {
          if (_this.props.keyGen[fieldsArray[key].name] !== undefined && _this.props.keyGen[fieldsArray[key].name].length === 0 && fieldsArray[key].default.length === 0) {
            if (fieldsArray[key].name !== "STATE_OR_PROVINCE_NAME" && (this.props.keyGen["LOCALITY_NAME"] !== "Київ" || this.props.keyGen["LOCALITY_NAME"] !== "Киев" || this.props.keyGen["LOCALITY_NAME"] !== "Kyiv")) {
              requires.push(fieldsArray[key].name)
            }
          }
      }
    })

    this.setState({requiredFields: requires})
  }

  componentDidMount() {
      var _this = this, requires = [], fieldsArray;
      
      this.props.actions.setKeyTypeSignatureSetted(false)
      this.props.actions.setKeyTypeAgreementSetted(false)
      this.props.actions.setIsPrivateCert(false);

      if (this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached !== null) {

          if (this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.SIGNATURE !== undefined) {
              this.props.actions.setKeyTypeSignatureSetted(false)
              this.setState({"signatureDisabled": true})
          }

          if (this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateRequestInfos !== undefined) {
              Object.keys(this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateRequestInfos).map((key, index) => {
                if(_this.props.tokenList.list[_this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateRequestInfos[key].extensions.keyUsage[0] === "DIGITAL_SIGNATURE") {
                    this.setState({"signatureDisabled": true})
                    return this.props.actions.setKeyTypeSignatureSetted(false)
                } else {
                  return null
                }
              })
          }

          if (this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.AGREEMENT !== undefined) {
              this.props.actions.setKeyTypeAgreementSetted(false)
              this.setState({"agreementDisabled": true})
          }

          if (this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateRequestInfos !== undefined) {
              Object.keys(this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateRequestInfos).map((key, index) => {
                if(_this.props.tokenList.list[_this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateRequestInfos[key].extensions.keyUsage[0] !== "DIGITAL_SIGNATURE") {
                    this.setState({"agreementDisabled": true})
                    return this.props.actions.setKeyTypeAgreementSetted(false)
                } else {
                  return null
                }
              })
          }
      }

      this.setState({requiredFields: []})
      this.props.actions.setAllKeyGenParams(this.props.login.userData.confirmedProfile)
      this.props.actions.getRegistrarCips(this.props.base.serviceUrl, this.props.login.accessToken)
      .then((response) => {
            if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
              this.props.actions.setAllowedRegistarCipIdList(response)
              this.props.actions.setSelectedRegistarCipId(0)
              console.log(response);
              this.props.actions.getCertificateProfiles(this.props.base.serviceUrl, response[0].id, this.props.login.accessToken)
              .then((response) => {
                  if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
                    this.props.actions.setProfilesList(response)
                    this.props.actions.setSelectedProfile(0)

                    if (response[0] !== undefined) {
                      this.props.actions.setProfileFields(response[0].fields)
                      fieldsArray = this.props.profile.profilesList[0].fields

                      // eslint-disable-next-line
                      Object.keys(fieldsArray).map((key, index) => {
                        if (!fieldsArray[key].optional) {
                            if (this.props.keyGen[fieldsArray[key].name] !== undefined && this.props.keyGen[fieldsArray[key].name].length === 0 && fieldsArray[key].default.length === 0)
                            if (fieldsArray[key].name !== "STATE_OR_PROVINCE_NAME" && (this.props.keyGen["LOCALITY_NAME"] !== "Київ" || this.props.keyGen["LOCALITY_NAME"] !== "Киев" || this.props.keyGen["LOCALITY_NAME"] !== "Kyiv")) {
                              requires.push(fieldsArray[key].name) 
                            }
                        }
                      })
                      // this.setState({requiredFields: requires})
                    } else {
                      requires.push("certProfile") 
                    }

                    this.setState({requiredFields: requires})
                    
                    

                    this.setState({isLoading: false})
                    console.log(response);
                  } else {
                    this.props.actions.setActionResultData({status: "failed", action: "keyGenTitle", message: response.json.message})
                    this.props.actions.setShowActionResult(true);

                    window.setTimeout(function () {
                      _this.props.actions.setShowGenerateKeyPopup(false);
                    }, 50)
                  }
              })

            } else {
              // if (response.json.message !== undefined && response.json.message.length > 0) {
              //     alert(response.json.message)
              //     this.props.actions.setShowGenerateKeyPopup(false);
              // }
              this.props.actions.setErrorResponse(response.json)
              this.props.actions.setShowError(true);
              this.props.actions.setShowGenerateKeyPopup(false);
            }
      })
  }

  checkKeyType (type) {
    var _this = this, result;

    if (Object.keys(this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateRequestInfos).length === 0) {
        if (type === "DIGITAL_SIGNATURE") {
          result = false;
        } else {
          result = true;
        }
    } else {
        // eslint-disable-next-line
        Object.keys(this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateRequestInfos).map((key, index) => {
          if (_this.props.tokenList.list[_this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateRequestInfos[key].extensions.keyUsage[0] === "DIGITAL_SIGNATURE") {
            result = true;
          } else {
            result = false
          }
        })
    }

    return result
  }

  componentWillUnmount () {
      // this.props.actions.clearAllKeyGenParams()
  }

  render() {
    let isEnabled = this.props.profile.profilesList.length > 0 && this.state.requiredFields.length === 0 && (this.props.profile.keyTypeSignatureSetted === true || this.props.profile.keyTypeAgreementSetted === true) ? true : false;
      return (
          <div className='popup'>
            <div className="popup_inner_key">
              <h4 style={{paddingLeft: "15px", paddingTop: "30px"}}>{i18next.t("keyGenTitle")}</h4>
              {
                this.state.isLoading && this.props.profile.selectedProfile === null
                ? <div style={{textAlign: "center", marginBottom: "inherit"}}><i style={{fontSize: "5em"}} className="fa fa-spinner fa-pulse"></i></div>
                : null
              }
              <div className="row col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{paddingLeft: "0px", paddingRight: "0px"}}>
                <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12" style={{paddingLeft: "0px"}}>
                  {
                      this.props.profile.selectedRegistarCipId !== null
                      ? <div className="col-12 col-form-label" style={{paddingTop: "0px", paddingBottom: "0px", paddingRight: "0px"}}>
                          <label className="col-12 col-form-label" style={{paddingLeft: "0px", fontSize: "15px", color: "#b5b1af"}}>{i18next.t("knedp")}</label>
                          <select type="select" disabled={this.props.profile.allowedRegistarCipIdList.length <= 1 ? true : false} className="form-control" defaultValue={this.props.profile[this.props.profile.selectedRegistarCipId]} style={{textAlign: "center", textAlignLast: "center"}}>
                              {
                                Object.keys(this.props.profile.allowedRegistarCipIdList).map((key, index) => 
                                  <option key={index} value={index}>{this.props.profile.allowedRegistarCipIdList[key].title}</option>)
                              }
                          </select>
                        </div>
                      : null
                  }
                  {
                      this.props.profile.selectedProfile !== null
                      ? <div className="col-12 col-form-label" style={{paddingTop: "0px", paddingBottom: "0px", paddingRight: "0px"}}>
                          <label className="col-12 col-form-label" style={{paddingLeft: "0px", fontSize: "15px", color: "#b5b1af"}}>{i18next.t("certProfile")}</label>
                          <select type="select" onChange={this.handleChangeCertificateProfile} disabled={this.props.profile.profilesList.length <= 1 ? true : false} className={this.props.profile.profilesList.length === 0 ? "form-control is-invalid" : "form-control"} defaultValue={this.props.profile[this.props.profile.selectedProfile]} style={{textAlign: "center", textAlignLast: "center"}}>
                              {
                                Object.keys(this.props.profile.profilesList).map((key, index) => 
                                  <option key={index} value={index}>{this.props.profile.profilesList[key].caption}</option>)
                              }          
                          </select>
                        </div>
                      : null
                  }

                  {
                      this.props.profile.selectedRegistarCipId !== null
                      ? <div className="col-12 col-form-label" style={{paddingTop: "0px", paddingBottom: "0px", paddingRight: "0px"}}>
                            <label className="col-12 col-form-label" style={{paddingLeft: "0px", fontSize: "15px", color: "#b5b1af"}}>{i18next.t("purpose")}</label>
                            <div className="form-check form-check-inline col-12" style={{marginLeft: "0px", paddingRight: "0px"}}>
                                <input type="checkbox" disabled={this.state.signatureDisabled} checked={this.props.profile.keyTypeSignatureSetted} className="form-check-input" onChange={this.handleCheckSignature.bind(this)} />
                                <label className="form-check-label">
                                  {i18next.t("SIGNATURE")}
                                </label>
                            </div>
                            <div className="form-check form-check-inline col-12" style={{marginLeft: "0px", paddingRight: "0px"}}>
                                <input type="checkbox" disabled={this.state.agreementDisabled} checked={this.props.profile.keyTypeAgreementSetted} className="form-check-input" onChange={this.handleCheckAgreement.bind(this)} />
                                <label className="form-check-label">
                                  {i18next.t("AGREEMENT")}
                                </label>
                            </div>

                            {/*
                              <select type="select" className="form-control" defaultValue={this.props.profile.generatedKeysType} style={{textAlign: "center", textAlignLast: "center"}} onChange={this.handleChangeKeyType}>
                                {
                                  this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached === null || this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.SIGNATURE === undefined
                                  ? <option key={0} value="SIGNATURE">{i18next.t("SIGNATURE")}</option>
                                  : null
                                }
                                {
                                  this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached === null || this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.AGREEMENT === undefined
                                  ? <option key={1} value="AGREEMENT">{i18next.t("AGREEMENT")}</option>
                                  : null
                                }      
                              </select>
                            */}
                        </div>
                      : null
                  }

                  <label className="col-12 col-form-label" style={{paddingLeft: "15px", fontSize: "15px", color: "#b5b1af"}}>{i18next.t("optionsLabel")}</label>
                  {
                      this.props.profile.selectedRegistarCipId !== null
                      ? <div className="form-check form-check-inline col-12" style={{marginLeft: "15px", paddingRight: "0px"}}>
                            <input type="checkbox" checked={this.props.profile.isElectronicStamp} className="form-check-input" onChange={this.handleCheck.bind(this)} />
                            <label className="form-check-label">
                              {i18next.t("electronicStamp")}
                            </label>
                        </div>
                      : null
                  }

                  {
                      this.props.profile.selectedRegistarCipId !== null
                      ?   <div className="form-check form-check-inline col-12" style={{marginLeft: "15px", paddingRight: "0px"}}>
                              <input type="checkbox" disabled={this.props.login.certificateIssuingGrant === true ? false : true} checked={this.props.profile.certificateAutoIssuing} className="form-check-input" onChange={this.handleCheckCertAutoIssuing.bind(this)} />
                              <label className="form-check-label">
                                {i18next.t("certificateAutoIssuing")}
                              </label>
                          </div>
                      : null
                  }
                  {
                      this.props.profile.selectedRegistarCipId !== null
                      ?   <div className="form-check form-check-inline col-12" style={{marginLeft: "15px", paddingRight: "0px", alignItems: "flex-start"}}>
                              <input type="checkbox" checked={this.props.profile.isPrivateCert} className="form-check-input" style={{marginTop: "5px"}} onChange={this.handleCheckIsPrivateCert.bind(this)} />
                              <label className="form-check-label">
                                {i18next.t("dontPublicCert")}
                              </label>
                          </div>
                      : null
                  }
                  {
                    this.state.requiredFields.length > 0
                    ? <div className="col-12 col-form-label" style={{paddingTop: "0px", paddingBottom: "0px", paddingRight: "0px"}}>
                        <label className="col-12 col-form-label" style={{paddingLeft: "0px", fontSize: "15px", color: "#DB4C4C"}}>{i18next.t("requiredFieldsNotFilled")}</label>
                          <div style={{color: "#DB4C4C"}}>
                            {
                              this.state.requiredFields.map((key, index) => {
                                return (
                                   <span style={{fontWeight: "600", fontSize: "14px"}}>
                                    {
                                      index > 0 ? ", " + i18next.t(key) : i18next.t(key)
                                    }
                                  </span>)
                              })
                            }
                          </div>
                        <label className="col-12 col-form-label" style={{paddingLeft: "0px", fontSize: "15px", color: "#DB4C4C"}}>{i18next.t("contactOwner")}</label>
                      </div>
                    : null
                  }
                </div>
                <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12" style={{paddingLeft: "0px"}}>
                  {
                    this.props.profile.fields.length > 0
                      ? <div className="popup_inner_key_gen">
                            {
                                Object.keys(this.props.profile.fields).map((key, index) => {
                                  return (<div key={index}>
                                              {
                                                !this.props.profile.fields[key].optional || (this.props.keyGen[this.props.profile.fields[key].name] !== undefined && this.props.keyGen[this.props.profile.fields[key].name].length > 0)
                                                    ? <div key={index}>
                                                          <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t(this.props.profile.fields[key].name)}</label>
                                                          <div className="col-12">
                                                              <input className={((this.props.keyGen[this.props.profile.fields[key].name] !== undefined && this.props.keyGen[this.props.profile.fields[key].name].length > 0
                                                                ? "form-control"
                                                                : (this.props.profile.fields[key].name === "STATE_OR_PROVINCE_NAME" && (this.props.keyGen[this.props.profile.fields[key].name] === undefined || this.props.keyGen[this.props.profile.fields[key].name].length === 0)) && (this.props.keyGen["LOCALITY_NAME"] === "Київ" || this.props.keyGen["LOCALITY_NAME"] === "Киев" || this.props.keyGen["LOCALITY_NAME"] === "Kyiv")
                                                                ? "form-control"
                                                                : (this.props.profile.fields[key].default !== undefined && this.props.profile.fields[key].default.length > 0)
                                                                ? "form-control"
                                                                : "form-control is-invalid"))} value={this.props.keyGen[this.props.profile.fields[key].name] !== undefined && this.props.keyGen[this.props.profile.fields[key].name].length > 0 ? this.props.keyGen[this.props.profile.fields[key].name] : this.props.profile.fields[key].default !== undefined && this.props.profile.fields[key].default.length > 0 ? this.props.profile.fields[key].default : ""} disabled />
                                                          </div>
                                                      </div>
                                                    : null
                                              }
                                          </div>)
                                })
                            }
                        </div>
                      : null
                  }
                </div>
              </div>

              {
                  this.props.profile.selectedRegistarCipId !== null
                  ? <div style={{float: "right", marginRight: "15px", paddingTop: "10px"}}>
                        <button className="btn btn-outline-info" style={{marginLeft: "5px", marginBottom: "30px"}} onClick={this.closePopup}>{i18next.t("cancelLabel")}</button>
                    </div>
                  : null
              }
              {
                  this.props.profile.selectedRegistarCipId !== null
                  ? <div style={{float: "right", paddingTop: "10px"}}>
                        <button className="btn btn-info" disabled={!isEnabled} style={{marginLeft: "5px", marginBottom: "30px"}} onClick={this.props.closeGenerateKeyPopup}>{i18next.t("okLabel")}</button>
                    </div>
                  : null
              }
            </div>  
          </div>
      );
  }
}

function mapStateToProps(state) {
    return {
        base: state.base,
        userReducer: state.userReducer,
        login: state.loginReducer,
        keyGen: state.keyGenReducer,
        profile: state.keyGenProfilesReducer,
        tokenList: state.tokenListReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
        setShowGenerateKeyPopup,
        setAllKeyGenParams,
        clearAllKeyGenParams,
        getRegistrarCips, getCertificateProfiles,
        setAllowedRegistarCipIdList, clearAllowedRegistarCipIdList,
        setSelectedRegistarCipId, clearSelectedRegistarCipId,
        setProfilesList, clearProfilesList, setSelectedProfile,
        clearSelectedProfile, setProfileFields, clearProfileFields,
        setErrorResponse, setShowActionResult, setActionResultData,
        setIsElectronicStamp, setGeneratedKeysType, setCertificateAutoIssuing,
        setShowError, setKeyTypeSignatureSetted, setKeyTypeAgreementSetted,
        setIsPrivateCert
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateKeyPopup);
