const baseState = {
    allowedRegistarCipIdList: [],
    selectedRegistarCipId: null,
    profilesList: [],
    selectedProfile: null,
    fields: [],
    isElectronicStamp: false,
    generatedKeysType: "SIGNATURE",
    keyTypeSignatureSetted: true,
    keyTypeAgreementSetted: true,
    certificateAutoIssuing: false,
    keyGenerationProcess: "processing",
	certificateIssuingProcess: "processing",
	certificateImportProcess: "processing",
	isPrivateCert: false
}

const keyGenProfilesReducer = (state = baseState, { type, payload }) => {

    switch (type) {

    	case "SET_ALLOWED_REGISTRAR_CIP_ID": {
			return {
				...state,
				allowedRegistarCipIdList: payload
			}
		}

		case "CLEAR_ALLOWED_REGISTRAR_CIP_ID": {
			return {
				...state,
				allowedRegistarCipIdList: []
			}
		}

		case "SET_SELECTED_REGISTRAR_CIP_ID": {
			return {
				...state,
				selectedRegistarCipId: payload
			}
		}

		case "CLEAR_SELECTED_REGISTRAR_CIP_ID": {
			return {
				...state,
				selectedRegistarCipId: null
			}
		}

		case "SET_SELECTED_PROFILE": {
			return {
				...state,
				selectedProfile: payload
			}
		}

		case "CLEAR_SELECTED_PROFILE": {
			return {
				...state,
				selectedProfile: null
			}
		}

		case "SET_PROFILE_FIELDS": {
			return {
				...state,
				fields: payload
			}
		}

		case "CLEAR_PROFILE_FIELDS": {
			return {
				...state,
				fields: []
			}
		}

		case "SET_PROFILES_LIST": {
			return {
				...state,
				profilesList: payload
			}
		}

		case "CLEAR_PROFILES_LIST": {
			return {
				...state,
				profilesList: []
			}
		}

		case "SET_IS_ELECTRONIC_STAMP": {
			return {
				...state,
				isElectronicStamp: payload
			}
		}

		case "SET_GENERATED_KEYS_TYPE": {
			return {
				...state,
				generatedKeysType: payload
			}
		}

		case "SET_CERTIFICATE_AUTO_ISSUING": {
			return {
				...state,
				certificateAutoIssuing: payload
			}
		}

		case "SET_KEY_GENERATION_PROCESS": {
			return {
				...state,
				keyGenerationProcess: payload
			}
		}

		case "SET_CERTIFICATE_ISSUING_PROCESS": {
			return {
				...state,
				certificateIssuingProcess: payload
			}
		}

		case "SET_CERTIFICATE_IMPORT_PROCESS": {
			return {
				...state,
				certificateImportProcess: payload
			}
		}

		case "SET_KEY_TYPE_SIGNATURE_SETTED": {
			return {
				...state,
				keyTypeSignatureSetted: payload
			}
		}

		case "SET_KEY_TYPE_AGREEMENT_SETTED": {
			return {
				...state,
				keyTypeAgreementSetted: payload
			}
		}

		case "SET_IS_PRIVATE_CERT": {
			return {
				...state,
				isPrivateCert: payload
			}
		}

        default:
            return state;
    }
}

export default keyGenProfilesReducer